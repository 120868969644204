@import '_variable.scss';

@font-face {
  font-family: 'Poppins-Regular';
  src:
    local('Poppins-Regular'),
    url('../assets/font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src:
    local('Poppins-SemiBold'),
    url('../assets/font/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins-Medium';
  src:
    local('Poppins-Medium'),
    url('../assets/font/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins-Light';
  src:
    local('Poppins-Light'),
    url('../assets/font/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins-Bold';
  src:
    local('Poppins-Bold'),
    url('../assets/font/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body,
div,
span,
label,
input,
textarea,
select,
a,
option,
li,
p,
th,
td,
button {
  font-family: Poppins-Regular !important;
}

header {
  z-index: 1200 !important;
}

.ck-horizontal-line {
  margin-bottom: -25px !important;
  hr {
    margin-top: -15px !important;
    width: 38%;
    height: 1px !important;
  }
}
// @media only screen and (min-height: 800px) {
//   .sidebar-footer {
//     position: absolute;
//     bottom: 15px;
//     width: 100%;
//   }
// }
// @media only screen and (min-height: 700px) {
//   .admin-sidebar-footer {
//     position: absolute;
//     bottom: 15px;
//     width: 100%;
//   }
// }
.light-theme {
  background-color: #f8f8f8;
}

.search {
  margin-right: 25px !important;
}

.filter {
  font-size: 32px !important;
  cursor: pointer;
}

.pr-16 {
  padding-right: 16px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.bg-white {
  background-color: $white-color;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.W-100 {
  width: 100%;
}

.W-50 {
  width: 50%;
}

.W-40 {
  width: 40%;
}

.W-30 {
  width: 30%;
}

.W-25 {
  width: 30%;
}

.W-20 {
  width: 30%;
}

.W-15 {
  width: 15%;
}

.show-text {
  color: #344051 !important;
  font-size: 14px !important;
  font-family: Poppins-Medium !important;
  line-height: normal;
  margin-right: 10px !important;
}

.page-dropbox {
  .MuiInputBase-root {
    border-radius: 8px !important;
    border: 1px solid #ddd !important;
    background: #fff !important;
  }
}

.header-name {
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  margin-left: 32px !important;
  margin-right: 8px !important;
}

.d-avator {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $theme-coclor;
  color: $white-color !important;
  font-size: 14px !important;
  cursor: pointer;
}

.sorting {
  position: relative;
  margin-left: 10px;

  img {
    cursor: pointer;
  }

  .up {
    position: absolute;
    top: 6px;
  }

  .down {
    position: absolute;
    bottom: 7px;
  }
}

.qcont:first-letter {
  text-transform: capitalize;
}

.pagination {
  align-items: center;

  nav {
    // border: 1px solid #d0d5dd;
    border-radius: 8px;
  }

  li:first-child {
    button {
      height: 32px;
      width: 61px;
      border: none;
      border-radius: 8px;
      font-size: 13px !important;
      font-family: Poppins-Medium !important;
      border: 1px solid #c7cdd8;

      &:not([disabled]) {
        color: #333333;
        background: #fdede9;
      }

      &:is([disabled]) {
        background-color: #c1c1c1 !important;
      }

      svg {
        display: none;
      }

      &::after {
        content: '';
        top: 0px;
        position: relative;
        font-size: 13px;
      }
    }
  }

  li:last-child {
    button {
      height: 32px;
      min-width: 50px;
      border-radius: 8px;
      // border: none !important;
      padding-left: 10px;
      padding-right: 16px;
      font-size: 13px !important;
      font-family: Poppins-Medium !important;
      border: 1px solid #c7cdd8;

      &:not([disabled]) {
        color: #333333;
        background: #fdede9;
      }

      &:is([disabled]) {
        background-color: #c1c1c1 !important;
      }

      svg {
        display: none;
      }

      &::before {
        content: '';
        top: 0px;
        position: relative;
        font-size: 13px;
      }
    }
  }

  .MuiPaginationItem-ellipsis {
    // border-left: 1px solid #d0d5dd;
    border-radius: unset;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }

  li {
    button {
      color: white;
      background-color: #2e162e;
      height: 32px;
      font-size: 13px !important;
      font-family: Poppins-Medium !important;
      border-radius: 8px !important;
      min-width: 32px;
      border-radius: unset;
      border-left: 1px solid #f1f1f1;
      margin: unset;
      margin-right: 5px !important;

      &.Mui-selected {
        background-color: #2e162e;
        color: $white-color;

        &:hover {
          background-color: #2e162e !important;
        }
      }
    }
  }
}

.role-tbody {
  tr {
    td {
      padding-top: 22px !important;
      padding-bottom: 22px !important;
    }

    td:first-child {
      vertical-align: baseline;
    }
  }
}

.custom-table {
  .pl-62 {
    padding-left: 52px !important;
  }

  .pl-123 {
    padding-left: 123px !important;
  }

  thead {
    .arrow-box {
      position: relative;
      margin-left: 3px;
      height: 10px;
      width: 7px;

      .down {
        position: absolute;
        bottom: -10px;
        fill: #d8d8d8;
        cursor: pointer;
      }

      .up {
        position: absolute;
        position: absolute;
        top: -10px;
        fill: #d8d8d8;
        cursor: pointer;
      }
    }

    th:first-child {
      padding-left: 20px;
    }

    th {
      padding: 15px 10px 15px 10px;
      background-color: #fdede9;
    }

    th,
    .th-title {
      color: #344051;
      font-family: Poppins-SemiBold !important;
      font-size: 14px !important;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;
    }

    th {
      white-space: nowrap;
    }
  }

  tbody {
    tr:nth-child(even) {
      background: #f9fafb;
    }

    tr:nth-child(odd) {
      background: #ffffff !important;
    }

    .d-avtaor {
      height: 32px;
      width: 32px;
      background-color: $blue-color;
      border-radius: 50%;
      color: $white-color;
      font-family: Poppins-Bold !important;
      font-size: 16px !important;
      letter-spacing: 0;
      line-height: 14px;
      margin-right: 10px;
    }

    .ivited-badge {
      height: 28px;
      width: 90px;
      border-radius: 12px;
      background-color: $blue-color;
      color: $white-color;
      font-family: Poppins-Bold !important;
      font-size: 12px !important;
      text-transform: uppercase;
      letter-spacing: 0.75px;
      line-height: 13px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mr-6 {
      margin-right: 6.5px;
    }

    .edit-btn {
      height: 40px;
      min-width: 71px !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      background-color: $white-color !important;
      color: $label-color;
      font-family: Poppins-Bold !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 14px;
      text-transform: capitalize !important;
      text-align: center;
      padding-left: 16px !important;
      padding-right: 14px !important;
    }

    .ip-img {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      background-color: $theme-coclor;
      color: #fff !important;
      border-radius: 50%;
      font-size: 12px !important;

      .default-ippic {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 300px;
        box-shadow:
          0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
        text-align: center;
        vertical-align: middle;
      }

      .default-iptext {
        height: 100%;
        border-radius: 300px;
        box-shadow:
          0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ip-button,
    .dip-button {
      display: flex;
      width: 96px;
      height: 34px;
      padding: 10px 12px;
      padding-right: 20px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid $theme-coclor !important;
      background: $white-color;
      cursor: pointer;
      // figma styles
      color: #667085 !important;

      /* Desktop/Body/Small/Small -> Medium */
      font-family: Poppins-Medium !important;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      /* 133.333% */
    }

    .dip-button {
      color: $white-color !important;
      background-color: $theme-coclor;
    }

    .active-badge {
      height: 28px;
      width: 82px;
      border-radius: 12px;
      background: #ebf6eb;
      color: #31aa27 !important;
      font-size: 12px !important;
      line-height: 13px;
      text-align: center;
      padding: 6px 18px;
      // width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .inactive-badge {
      height: 28px;
      width: 90px;
      border-radius: 12px;
      background: #fff0f0;
      color: #f93232 !important;
      font-size: 12px !important;
      line-height: 13px;
      text-align: center;
      padding: 6px 18px;
      // width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    td:first-child {
      padding-left: 20px;
    }

    td {
      padding: 15.75px 10px;
      color: #344051;
      font-size: 14px !important;
      letter-spacing: 0;
      line-height: 14px;
      transition: 0.5s;
      word-break: break-word;

      img {
        cursor: pointer;
      }

      .btn-over {
        display: none;
        transition: 0.5s;
      }

      button {
        padding: unset !important;

        svg {
          fill: $label-color !important;
        }
      }
    }

    tr {
      transition: 0.5s;

      .last-td {
        position: fixed;
        right: 0;
        border-bottom: unset !important;
      }

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

        .btn-over {
          display: flex !important;
        }
      }
    }

    .company-name {
      color: $label-color !important;
      font-family: Poppins-Bold !important;
      font-size: 16px !important;
      letter-spacing: 0;
      line-height: 14px;
      max-width: 280px;
    }
  }
}

.cel-bor {
  border-bottom: 1px solid #e4e7ec;
  margin-bottom: 22px;
}

.pad-bor {
  .inpad-bor {
    .cell-per-box {
      p {
        color: #344051 !important;
        font-size: 14px !important;
        margin-bottom: 23px;
      }
    }
  }

  .inpad-bor:last-child {
    .cell-per-box {
      p {
        margin-bottom: 23px !important;
      }
    }
  }
}

.admin-sidebar-footer {
  .MuiList-root {
    padding-top: unset !important;

    span,
    .MuiListItemIcon-root {
      font-size: 14px !important;
      color: #fff !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      font-family: Poppins-Regular !important;
    }
  }

  .MuiButtonBase-root {
    padding: unset !important;
  }
}

.submenu-pop {
  .MuiPopover-paper {
    border-radius: 6px !important;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25) !important;
  }
}

.submenu-box {
  padding: 16px 0px 0px 0px;

  // .submenu-item:last-child{
  //   margin-bottom: unset !important;
  // }
  .submenu-item {
    color: #344051 !important;
    font-family: Poppins-Medium !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    cursor: pointer;
    min-height: 38px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:hover {
      background-color: #ededed;
    }
  }
}

.sidebar-footer,
.admin-sidebar-footer {
  position: sticky;
  bottom: 0px;
  width: 100%;
  background-color: #2e162e;

  .MuiList-root {
    padding-top: unset !important;
  }

  .MuiButtonBase-root {
    margin: unset !important;
    border-radius: unset !important;
    min-height: 30px;
    padding-left: 2px !important;
    padding-right: 2px !important;

    .MuiListItemIcon-root {
      margin-right: 9px;
    }

    .MuiListItemText-primary {
      color: $white-color;
      // font-family: Poppins-Medium !important;
      // font-weight: 500 !important;
      line-height: 20px !important;
      font-size: 14px !important;
    }
  }
}

.MuiToolbar-root {
  padding-left: 0px !important;
}

.pass-title {
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
  margin-top: 16px !important;
}

.pass-info {
  color: #333 !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  margin-bottom: 8px !important;

  .tick {
    color: green;
    font-size: 14px !important;
    margin-right: 5px;
  }

  .wicon {
    color: red;
    font-size: 14px !important;
    margin-right: 5px;
  }
}

.MuiList-root {
  .sideBarActive {
    // background-color: #fdede912 !important;
    background-color: $white-color;

    .MuiListItemText-primary {
      color: $theme-coclor !important;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      min-height: 55px;
      width: 3px;
      background-color: $theme-coclor;
    }
  }
}

.sideBarActive:hover {
  background-color: $white-color !important;
}

.sidebarLink {
  text-decoration: none !important;
  color: unset !important;

  .MuiButtonBase-root {
    margin: unset !important;
    border-radius: unset !important;
    min-height: 55px;
    padding-left: 18px !important;
    padding-right: 10px !important;

    .MuiListItemIcon-root {
      margin-right: 19px;

      .MuiAvatar-root {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
    }

    .MuiListItemText-primary {
      color: #fff;
      font-family: Poppins-Medium !important;
      font-weight: 500 !important;
      line-height: 24px !important;
      font-size: 16px !important;
    }
  }
}

.linkHide {
  text-decoration: none !important;
  color: unset !important;
}

.d-flex-cc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-a {
  display: flex;
  align-items: center;
}

.d-flex-nwrp {
  flex-wrap: nowrap;
}

.d-flex-wrp {
  flex-wrap: wrap;
}

.d-flex {
  display: flex;
}

.flex-sb {
  justify-content: space-between;
}

.d-flex-sa {
  display: flex;
  justify-content: space-around;
}
.flex-end {
  justify-content: flex-end;
}

.pointer {
  cursor: pointer;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px;
}

.select-arrow {
  fill: $label-color;
}

.d-flex-ja {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .banner-text {
    color: #384558 !important;
    font-size: 34px !important;
    line-height: 40.6px;
    font-family: Poppins-Light !important;

    span {
      color: #384558 !important;
      font-family: Poppins-SemiBold !important;
    }
  }
}

.vh-100 {
  min-height: 100vh;
}

.w-100 {
  width: 100%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.pl-15 {
  padding-left: 15vw;
  padding-right: 50px;
}

.mt-5r {
  margin-top: 6rem !important;
}

.wel-text {
  color: #000 !important;
  font-size: 21px !important;

  span {
    font-size: 21px !important;
    color: $theme-coclor !important;
    font-family: Poppins-SemiBold !important;
  }
}

.signup-txt {
  color: #8d8d8d !important;
  font-size: 16px !important;
  line-height: normal !important;

  span {
    color: $theme-coclor !important;
    font-size: 16px !important;
  }
}

.px-40 {
  padding: 40px;
}

.fixed-loader {
  height: 100vh;
  background: #6b696914;
  position: fixed;
  z-index: 1300;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadBox {
  // background-color: #ffffff00;
  // border-radius: 50%;
  position: relative;
  width: 55px;
  // height: 85px;
  // background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  align-items: center;
  justify-content: center;

  // row-gap: 0px;
  .t-box {
    width: 25px;
    height: 25px;
    background-color: $theme-coclor;
    animation: blink 2s linear infinite;
    animation-delay: 1s;
  }

  .s-box {
    width: 25px;
    height: 25px;
    background-color: #2e162e;
    animation-delay: 4s;
    animation: blink 2s linear infinite;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes blink2 {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  .line-circle {
    border: 5px solid #fff;
    border-bottom-color: $theme-coclor;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .im1 {
    width: 100px;
    height: 100px;
  }

  .centerl {
    position: absolute;
    left: 35px;
    top: 30px;
  }
}

.for-text {
  color: #333 !important;
  font-family: Poppins-SemiBold !important;
  font-size: 36px !important;
  line-height: 44px !important;
  /* 122.222% */
  letter-spacing: -0.72px !important;
  margin-bottom: 12px !important;
}

.otp-text {
  color: #344051 !important;
  font-family: Poppins-SemiBold !important;
  font-size: 20px !important;
  line-height: 20px !important;
  margin-bottom: 24px !important;
}

.for-dis {
  color: #667085 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  // margin-bottom: 32px !important;
}

.back-text {
  color: $theme-coclor !important;
  font-family: Poppins-SemiBold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-top: 24px !important;
  cursor: pointer;
}

.login-form {
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.9);
  width: 539px;
  padding: 60px 0px 0px 0px;
  min-height: 600px;

  .for-log-box {
    padding: 0px 0px 44px 0px !important;
  }

  .login-box,
  .log-title {
    padding: 0px 44px;
  }

  .info-text {
    color: $label-color;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 15px;
  }

  .link-text {
    color: #979598;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: 14px;
  }

  .back-btn {
    color: $blue-color;
    text-decoration: underline solid $blue-color;
    font-family: Poppins-SemiBold !important;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: capitalize !important;
    line-height: 14px;
    margin-top: 36px;
    margin-bottom: 8px;

    &:hover {
      text-decoration: underline solid $blue-color;
    }
  }

  .logo-text {
    margin-bottom: 91px;
    font-family: Poppins-Medium !important;
    color: #000;
    font-size: 55px;
    line-height: 55px;
    margin-top: 7px;
  }

  .s-dis {
    color: #667085 !important;
    font-size: 16px !important;
    line-height: 24px;
    /* 150% */
    margin-bottom: 32px;
  }

  .custom-field {
    margin-bottom: 24px;

    p {
      color: #000 !important;
      font-size: 16px !important;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .custom-input {
      width: 100%;

      .MuiInputBase-root {
        height: 50px;
        border-radius: 6px;
        border: 1px solid #adadad;
        background-color: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &.Mui-error {
          border: 1px solid $red-color;
        }

        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      .MuiInputBase-input {
        padding: 13px 11px;
        font-size: 14px !important;

        &::placeholder {
          color: #bdbdbd !important;
          font-size: 14px !important;
        }
      }

      p.Mui-error {
        color: $red-color !important;
        margin: 4px 0px 0px 0px !important;
        font-size: 12px !important;
      }
    }
  }

  .submit-btn {
    height: 54px;
    width: 236px;
    color: $white-color;
    background-color: $theme-coclor;
    font-size: 16px !important;
    font-family: Poppins-Medium !important;
    line-height: 24px;
    border-radius: 10px;
    box-shadow: 0px 4px 19px 0px rgba(238, 75, 34, 0.3);
    text-transform: capitalize !important;
    margin-top: 56px;

    &:hover {
      background-color: $theme-coclor;

      cursor: pointer;
    }
  }

  .copy-text {
    color: $label-color;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
}

.border-bottom {
  border-bottom: 1px solid $divider-color;
}

.px-17 {
  padding-left: 17px;
  padding-right: 17px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.header-tab {
  color: $label-color !important;
  font-family: Poppins-SemiBold !important;
  font-size: 15px !important;
  letter-spacing: 1px !important;
  line-height: 14px !important;
  // min-height: 80px;
  padding: 35px 12px 30px 12px;
  display: flex;
  align-items: center;
  min-width: 86px;
  justify-content: center;
  cursor: pointer;
  position: relative;

  // &.mr-24 {
  //   // margin-right: 24px;
  // }
  &.active {
    &::after {
      content: '';
      height: 5px;
      background-color: $theme-coclor;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    // border-bottom: 5px solid $theme-coclor;
  }
}

.cancel-icon {
  cursor: pointer;
  fill: gray !important;
  height: 35px !important;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 30px !important;
}

.custom-pay-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 387px;
  min-height: 201px;
  border-radius: 10px;
  background-color: #fff;
  padding: 1.5rem;

  &:focus-visible {
    outline: none !important;
    border: none !important;
  }

  .pm-txt {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 22px;
    margin: 1.5rem 0;
  }
}

.n-cancel-btn {
  min-width: 120px !important;
  height: 36px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-family: Arial-Bold !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  border: 1px solid #cccccc;
  position: relative;
  text-transform: capitalize !important;
}

.breadcrumbs {
  min-height: 40px;
  background-color: #f8f8f8;
  padding-left: 24px;
  position: sticky;
  top: 72px;
  z-index: 10;

  .MuiBreadcrumbs-separator {
    display: none;
  }

  .arrows {
    margin: 0 4px 0 6px;
  }

  .home {
    width: 14px;
    height: 15px;
  }

  li {
    p {
      color: #344051 !important;
      font-family: Poppins-Medium !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 20px !important;
    }

    a {
      color: #637083 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    .MuiTypography-root {
      display: flex;
      align-items: center;
    }
  }
}

.p-title {
  color: $label-color !important;
  font-family: Poppins-SemiBold !important;
  font-size: 24px !important;
  letter-spacing: 0px !important;
  line-height: 21px !important;
}

.profile-modal {
  .MuiBox-root {
    &:focus-visible {
      border: none;
      outline: none;
    }

    .labels {
      color: $label-color !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 14px !important;
      margin-bottom: 20px !important;
    }

    .details {
      color: $label-color !important;
      font-family: Poppins-SemiBold !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 14px !important;
      word-break: break-all;
      margin-bottom: 20px !important;
    }

    .close-text {
      color: $blue-color !important;
      text-decoration: underline solid $blue-color;
      font-family: Poppins-SemiBold !important;
      font-size: 16px !important;
      letter-spacing: 0;
      line-height: 14px !important;
      cursor: pointer;
    }
  }
}

.set-parent {
  min-height: calc(100vh - 80px);

  .set-child {
    min-height: 237px;
    width: 500px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);

    .px-24 {
      padding: 0 24px;
    }

    .sett-box {
      min-height: 63px;
      border-bottom: 1px solid $divider-color;

      svg {
        cursor: pointer;
        fill: $label-color;
        font-size: 16px;
      }
    }
  }
}

.n-btn {
  color: $label-color !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
  line-height: 14px !important;
  text-decoration: underline solid $label-color !important;
  text-transform: capitalize !important;
}

.save-btn {
  height: 48px;
  min-width: 124px !important;
  border-radius: 4px !important;
  background-color: $save-color !important;
  color: $white-color !important;
  font-family: Poppins-Bold !important;
  font-size: 15px !important;
  letter-spacing: 0.94px !important;
  line-height: 14px !important;
}

.cancel-btn {
  height: 36px;
  min-width: 96px !important;
  border-radius: 6px !important;
  border: 1px solid #d0d5dd !important;
  background: $white-color !important;
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 12px;
  text-transform: capitalize !important;
}

.f-16 {
  font-size: 16px !important;
  color: #344051 !important;
}

.f-16-semibold {
  font-family: Poppins-SemiBold !important;
  font-size: 16px !important;
  color: #344051 !important;
  margin-bottom: 8px !important;
}

.wh-32 {
  width: 32px;
  height: 32px;
}

.s-add-btn {
  height: 36px;
  min-width: 96px !important;
  border-radius: 6px !important;
  border: 1px solid $theme-coclor !important;
  background: $theme-coclor !important;
  color: $white-color !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 12px;
  text-transform: capitalize !important;
}

.h-44 {
  min-height: 44px;

  p {
    color: $label-color !important;
    font-size: 16px !important;
    letter-spacing: 0;
    line-height: 14px !important;
    //margin-left: 10px !important;
  }
}

.normal-checkbox {
  height: 24px;
  width: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #efefef;
}

.parent-checkbox {
  margin-left: unset !important;
}

.ml-40 {
  margin-left: 40px !important;
}
.ml-60 {
  margin-left: 60px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-24 {
  margin-left: 24px;
}

.MuiFormControlLabel-label {
  color: #344054 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  font-family: Poppins-Medium !important;
  margin-left: 6px !important;
}

.MuiFormControlLabel-root {
  margin: unset !important;
}

.foot-text {
  color: #667085 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.forgot-text {
  color: $theme-coclor !important;
  font-family: Poppins-SemiBold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  cursor: pointer;
}

.custom-checkbox {
  padding: unset !important;

  .MuiCheckbox-root {
    &.Mui-checked {
      color: $save-color !important;
    }
  }

  span {
    color: $label-color !important;
    font-size: 16px !important;
    letter-spacing: 0;
    line-height: 14px !important;
  }
}

.h-161 {
  min-height: calc(100vh - 161px);
}

.modal-gmail {
  color: $label-color !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 14px !important;
  margin-top: 7px !important;
  word-break: break-all;
}

.modal-name {
  color: $label-color !important;
  font-family: Poppins-Bold !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 14px !important;
  word-break: break-all;
}

.add-photo {
  color: $blue-color !important;
  font-family: Poppins-Bold !important;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 13px !important;
  text-decoration: underline solid $blue-color;
  cursor: pointer;
  margin-top: 6px !important;
}

.text-center {
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-8 {
  padding-left: 8px;
}

.mt-10 {
  margin-top: 10px !important;
}

.warn-text {
  margin-top: 4px;
  color: $red-color !important;
  font-size: 12px !important;
  font-style: italic;
  letter-spacing: 0;
  line-height: 11px !important;
}

.warn-text-15 {
  margin-top: 30px !important;
  padding-left: 10px;
  color: $red-color !important;
  font-size: 15px !important;
  font-style: italic;
  letter-spacing: 0;
  line-height: 11px !important;
}

.normal-text {
  color: $label-color !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 14px !important;
}
.accordion {
  background-color: #f2f4f7 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}
.custom-switch {
  height: 31px !important;
  width: 51px !important;
  padding: unset !important;
  border-radius: 30px !important;
  margin: 0 14px 0 8px;

  .MuiSwitch-thumb {
    height: 28px !important;
    width: 28px !important;
    border: 0.5px solid rgba(0, 0, 0, 0.04) !important;
    background-color: #ffffff !important;
    margin-top: 1px;
    box-shadow:
      0 3px 8px 0 rgba(0, 0, 0, 0.15),
      0 3px 1px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .MuiSwitch-track {
    background-color: $grey-color;
  }

  .MuiButtonBase-root.Mui-checked + .MuiSwitch-track {
    background-color: #6fc889 !important;
    opacity: 1 !important;
  }

  .MuiSwitch-switchBase {
    padding: 1px !important;
  }
}

.profile-drop {
  .MuiPopover-paper {
    width: 224px;
    border-radius: 8px !important;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25) !important;
  }
}

.leave-profile-drop {
  .MuiPopover-paper {
    width: 160px;
    border-radius: 8px !important;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25) !important;
  }
}

.menu-box {
  padding: 20px 0;
}
.skills-Box {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 100%;
  min-height: 191px;
  padding: 17px;
  top: 50%;
  left: 50%;
  border: none;
}
.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.skill-tag {
  padding: 5px 10px;
  border: 1px solid #5b6474;
  border-radius: 4px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.sync-loader {
  transform-origin: center;
}

.sync-anim {
  animation: syncRotate 0.9s infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes syncRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logout-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 600px;
  min-height: 191px;
  padding: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;

  &.p-32 {
    padding: 32px;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  .l-title {
    color: #344051 !important;
    font-family: Poppins-Medium !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-bottom: 12px;
    text-align: center;
  }

  .l-info {
    color: #344051 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-bottom: 27px;
    text-align: center;
  }

  .l-cancel-btn {
    border-radius: 6px !important;
    border: 1px solid #637083 !important;
    height: 36px;
    min-width: 64px !important;
    background: $white-color !important;
    color: #344051 !important;
    font-family: Poppins-Medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 10px 12px;
    text-transform: capitalize !important;
  }

  .l-save-btn {
    border-radius: 4px !important;
    height: 36px;
    min-width: 64px !important;
    background: $theme-coclor !important;
    color: $white-color !important;
    font-family: Poppins-Medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 10px 12px;
    text-transform: none !important;
  }
}

.email-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 762px;
  min-height: 586px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 29px 30px 32px 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
  }

  // .ck-editor__editable {
  //   height: 95px;
  // }
  .l-title {
    color: #344051 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .l-info {
    color: #344051 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-bottom: 27px;
    text-align: center;
  }

  .l-cancel-btn {
    border-radius: 6px !important;
    border: 1px solid #637083 !important;
    height: 36px;
    min-width: 64px !important;
    background: $white-color !important;
    color: #344051 !important;
    font-family: Poppins-Medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 10px 12px;
    text-transform: capitalize !important;
  }

  .l-save-btn {
    border-radius: 4px !important;
    height: 36px;
    min-width: 64px !important;
    background: $theme-coclor !important;
    color: $white-color !important;
    font-family: Poppins-Medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 10px 12px;
    text-transform: none !important;
  }
}

.Degree-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 440px;
  // height: 191px;
  padding: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
  }

  .l-title {
    color: #344051 !important;
    font-family: Poppins-Medium !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-bottom: 12px;
    text-align: center;
  }

  .l-info {
    color: #344051 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-bottom: 27px;
    text-align: center;
  }

  .l-cancel-btn {
    border-radius: 6px !important;
    border: 1px solid #637083 !important;
    height: 36px;
    width: 64px !important;
    background: $white-color !important;
    color: #344051 !important;
    font-family: Poppins-Medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 10px 12px;
    text-transform: capitalize !important;
  }

  .l-save-btn {
    border-radius: 4px !important;
    height: 36px;
    width: 64px !important;
    background: $theme-coclor !important;
    color: $white-color !important;
    font-family: Poppins-Medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 14px 12px;
    text-transform: none !important;
  }
}

.bdiveder {
  height: 1px;
  background-color: #d9d9d9;
  margin: 16px 0px !important;
}

.s-cancel-btn {
  border-radius: 6px !important;
  border: 1px solid #637083 !important;
  height: 36px;
  min-width: 64px !important;
  background: $white-color !important;
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 12px !important;
  text-transform: capitalize !important;
}

.s-save-btn {
  border-radius: 4px !important;
  height: 36px;
  min-width: 64px !important;
  background: $theme-coclor !important;
  color: $white-color !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 14px 12px !important;
  text-transform: none !important;
}

.pad-box {
  padding: 28px 31px;

  .pro-box {
    border-radius: 8px;
    background: #fff;
    min-height: 659px;

    .ban-img {
      height: 160px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .default-pic {
        position: relative;
        // left: 25%;
        bottom: -50%;
        // margin-bottom: 75px;
        object-fit: cover;
        background-color: $theme-coclor;
        color: $white-color;
        font-size: 46px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        border-radius: 200px;
        border: 4px solid #fff;
        box-shadow:
          0px 4px 6px -2px rgba(16, 24, 40, 0.03),
          0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      }
    }

    .pro-name {
      color: #344051 !important;
      font-family: Poppins-Medium !important;
      font-size: 30px !important;
      font-weight: 500 !important;
      line-height: 38px !important;
      margin-top: 106px;
      margin-bottom: 4px;
      text-align: center;
    }

    .pro-role {
      color: #637083 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      opacity: 0.7;
      text-align: center;
    }

    .theme-btn {
      height: 36px;
      min-width: 107px;
      border-radius: 4px;
      border: 1px solid $theme-coclor;
      background: $white-color;
      color: $theme-coclor !important;
      font-size: 12px !important;
      font-family: Poppins-Medium !important;
      text-transform: capitalize;
    }
  }

  .pro-box2 {
    // min-width: 350px;
    // min-height: 254px !important;
    border-radius: 8px;
    background: #fff;
    padding: 32px;
    padding-left: 0px;
    padding-right: 0px;

    .side-client-text {
      padding-left: 32px;
      padding-right: 32px;
      font-family: Poppins-Medium !important;
      font-size: 18px;
      font-weight: 500 !important;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      position: relative;
    }

    .side-client-text2 {
      font-family: Poppins-Medium !important;
      font-size: 18px;
      font-weight: 500 !important;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }

    .active {
      // border-left: 7px solid #ee4b22;
      background-color: #ced4da24;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 7px;
        height: 100%;
        width: 7px;
        background-color: #ee4b22;
      }
    }
  }
}

.np-box {
  border-bottom: 1px solid #ced4da;
  padding: 22px 24px;
  margin-bottom: 10px !important;

  .np-img {
    width: 40px;
    height: 40px;
    background-color: $theme-coclor;
    color: $white-color !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .np-name {
    color: #344051 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    word-break: break-word;
  }

  .np-role {
    color: #637083 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    opacity: 0.7;
    word-break: break-all;
  }
}

.pl-10 {
  padding-left: 10px;
}

.menu-item {
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  padding: 0px 26px;
  min-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }
}

.payment-menu-item {
  color: #ee4b22 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  padding: 0px 15px;
  min-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }
}

.footer-Main {
  @media (min-width: 900px) {
    flex-wrap: nowrap !important;
    flex-shrink: 0 !important;
    flex-basis: auto !important;
    max-width: auto !important;
    width: auto !important;
  }
}

.leave-menu-item {
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  padding: 0px 26px;
  min-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
  }
}

.footer-box {
  padding: 10px 24px 10px 16px;

  .footer-item {
    color: #667085 !important;
    font-size: 14px !important;
    line-height: 20px !important;

    span {
      color: #344051 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      font-family: Poppins-SemiBold !important;
    }
  }
  .footer-item2 {
    margin-left: auto;
  }
}

.boder-right {
  white-space: nowrap;
}

.MuiInputBase-multiline {
  padding: unset !important;
}

.private-form {
  .submit-btn {
    margin-top: 20px;
    height: 44px;
    width: 100%;
    border-radius: 4px;
    color: $white-color;
    background-color: $theme-coclor;
    font-size: 15px !important;
    font-family: Poppins-SemiBold !important;
    letter-spacing: 1px !important;

    &:hover {
      cursor: pointer;
      background-color: $theme-coclor;
    }
  }

  .custom-field {
    .select-error {
      border: 1px solid $red-color !important;
      border-radius: 6px;

      div {
        border: unset !important;
      }
    }

    p {
      color: #344051 !important;
      font-size: 14px !important;
      font-family: Poppins-Medium !important;
      letter-spacing: 0;
      line-height: 20px !important;
      margin-bottom: 6px;

      span {
        color: $red-color !important;
      }

      &.MuiFormHelperText-root {
        color: $red-color !important;
        margin: 4px 0px 0px 0px !important;
        font-size: 12px !important;
      }
    }

    .link-color {
      cursor: pointer;
      color: #6941c6 !important;
    }

    margin-bottom: 16px;

    .custom-radio {
      .MuiFormControlLabel-root {
        // margin-right: 24px !important;
        margin-right: 9px !important;
      }
    }

    .MuiRadio-root {
      padding: unset;

      &.Mui-checked {
        color: $theme-coclor !important;
      }
    }

    .custom-input {
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      .MuiInputBase-root {
        min-height: 40px !important;
        border-radius: 6px !important;
        border: 1px solid #d0d5dd;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &.Mui-error {
          border: 1px solid $red-color;
        }

        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      p {
        &.MuiFormHelperText-root {
          color: $red-color !important;
          margin: 4px 0px 0px 0px !important;
          font-size: 12px !important;
        }

        &.Mui-error {
          color: $red-color !important;
          margin: 4px 0px 0px 0px !important;
          font-size: 12px !important;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      .MuiInputBase-root {
        min-height: 40px !important;
        border-radius: 6px !important;
        border: 1px solid #d0d5dd;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      .MuiInputBase-input {
        padding: 7px 16px !important;
        font-size: 14px !important;

        &::placeholder {
          color: #bdbdbd;
          font-size: 14px !important;
          font-family: Poppins-Medium !important;
        }
      }
    }

    .custom-date {
      width: 100%;

      .MuiInputBase-root {
        height: 40px;
        padding-left: 6px;
        border-radius: 6px;
        border: 1px solid #adadad;
        background-color: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &.Mui-error {
          border: 1px solid $red-color;
        }

        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      .MuiInputBase-input {
        padding: 13px 3px;
        font-size: 14px !important;

        &::placeholder {
          color: #bdbdbd !important;
          font-size: 14px !important;
        }
      }

      p.Mui-error {
        color: $red-color !important;
        margin: 4px 0px 0px 0px !important;
        font-size: 12px !important;
      }
    }
    .custom-email-input {
      width: 100%;

      span {
        &.Mui-error {
          color: $red-color !important;
          margin-top: 0px !important;
        }
      }

      p {
        &.MuiFormHelperText-root {
          color: $red-color !important;
          margin: 4px 0px 0px 0px !important;
          font-size: 12px !important;
        }

        &.Mui-error {
          color: $red-color !important;
          margin: 4px 0px 0px 0px !important;
          font-size: 12px !important;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      .MuiInputBase-root {
        min-height: 40px !important;
        border-radius: 6px !important;
        border: 1px solid #d0d5dd;
        background: #fff;
        padding: 2.5px 16px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      .MuiInputBase-input {
        font-size: 16px !important;

        &::placeholder {
          color: #bdbdbd !important;
          font-size: 14px !important;
        }
      }
    }

    .custom-big-input {
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      .MuiInputBase-root {
        height: 48px !important;
        border-radius: 7px !important;
        background-color: #f8f8fa;

        &::before,
        &::after {
          border-bottom: unset;
        }
      }

      .MuiInputBase-input {
        padding: 13px 11px;
        font-size: 16px !important;

        &::placeholder {
          color: #bdbdbd !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.pt-14 {
  padding-top: 14px !important;
}

input,
textarea {
  &::placeholder {
    color: #bdbdbd !important;
  }
}

.modal-yes-btn {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  color: $white-color !important;
  background-color: $red-color !important;
  font-size: 15px !important;
  font-family: Poppins-Bold !important;
  letter-spacing: 1px !important;

  &:hover {
    background-color: $red-color;
  }
}

.modal-no-btn {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  color: $white-color !important;
  background-color: $grey-color !important;
  font-size: 15px !important;
  font-family: Poppins-Bold !important;
  letter-spacing: 1px !important;

  &:hover {
    background-color: $grey-color;
  }
}

.modal-title {
  color: $label-color !important;
  font-family: Poppins-SemiBold !important;
  font-size: 18px !important;
  letter-spacing: 1.2px !important;
  line-height: 16px !important;
  text-transform: uppercase;
}

.modal-dis {
  color: $grey-color !important;
  font-size: 18px !important;
  letter-spacing: 0;
  line-height: 20px !important;
  margin-top: 6px !important;
  margin-bottom: 10px !important;
}

.closesvg {
  fill: $grey-color !important;
  cursor: pointer;
}

.main-content {
  padding: 24px;
  background-color: #f8f8f8;

  .white-box {
    background-color: $white-color;
    border-radius: 8px;
    padding: 32px;
    min-height: calc(100vh - 264px);
  }
}

.custom-search {
  .MuiInputBase-input {
    padding: 8.5px 14px 8.5px 0px;
    background: #fff !important;

    &::placeholder {
      color: #667085 !important;
      font-size: 14px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: 1px solid #d0d5dd !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  }
}

.title-add-box {
  background-color: $white-color;
  padding: 14px 80px 14px 24px;
}

.add-btn {
  height: 36px;
  min-width: 70px !important;
  border-radius: 4px !important;
  border: unset;
  background-color: $theme-coclor !important;
  color: $white-color !important;
  font-family: Poppins-Medium !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 14px !important;
  text-transform: capitalize !important;
  padding: 8px 16px !important;

  img {
    margin-right: 4px;
  }
}

.expot-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 12px !important;
  margin-top: 4px;

  & > span {
    height: 100%;
  }
}

.client-add-btn {
  display: inline-flex;
  height: 36px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: $theme-coclor;
  cursor: pointer;

  p {
    color: $white-color !important;
    font-family: Poppins-Medium !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 15px !important;
  }

  img {
    margin-right: 8px;
  }
}

.add-btn-apruv {
  cursor: pointer;
  border-radius: 6px;
  background: #fff;
  border: 1px solid $theme-coclor !important;
  color: $theme-coclor !important;
  height: 36px;
  font-family: Poppins-Medium !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
  display: inline-flex;
  height: 36px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;

  div {
    color: $theme-coclor !important;
    padding-top: 2px;
    font-family: Poppins-Medium !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }

  .apruv-cunt {
    padding-top: 2px;
    margin-left: 4px !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $theme-coclor !important;
    color: $white-color !important;
    font-family: Poppins-SemiBold !important;
    font-size: 10px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.page-title {
  font-family: Poppins-SemiBold !important;
  letter-spacing: 0;
  color: #2e162e !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px !important;
}
.report-title {
  font-family: Poppins-SemiBold !important;
  letter-spacing: 0;
  color: #2e162e !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px !important;
}
.report-title-name {
  font-family: Poppins-SemiBold !important;
  letter-spacing: 0;
  color: #ee4b22 !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px !important;
}
div {
  &.Mui-disabled {
    background-color: hsl(0, 0%, 95%) !important;
  }
}

.email-container {
  border-radius: 8px;
  background: #fff;
  min-height: 600px;

  .MuiTabs-root {
    .Mui-disabled {
      display: none;
    }

    // .MuiButtonBase-root:first-child{
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   z-index: 10;
    //   background-color: #fff;
    // }
  }

  .ck-editor__editable {
    height: 95px;
  }

  .email-item {
    min-height: 40px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e4e7ec;
    margin-bottom: 10px !important;
    padding: 12px 32px 12px 20px;

    .email-name {
      color: #344051 !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    .email-icons {
      img:first-child {
        margin-right: 30px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.custom-tab {
  .MuiTabs-root {
    min-height: 38px !important;
    margin-left: 18px !important;
  }

  .MuiTabPanel-root {
    padding: 24px 0px;
  }

  .MuiTabScrollButton-root {
    width: 25px !important;
  }

  // .MuiTabs-flexContainer {
  //  }
  .MuiTab-root {
    color: #929292;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 38px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    &.Mui-selected {
      color: black !important;
      background-color: #fff;
      font-family: Poppins-SemiBold !important;
    }
  }

  .MuiTabs-indicator {
    background-color: $theme-coclor;
  }
}
.custom-position-tab {
  .MuiTabs-root {
    min-height: 38px !important;
    // margin-left: 18px !important;
  }

  .MuiTabPanel-root {
    padding: 24px 0px;
  }

  .MuiTabScrollButton-root {
    width: 25px !important;
  }

  // .MuiTabs-flexContainer {
  //  }
  .MuiTab-root {
    color: #929292;
    display: block;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 38px !important;
    padding-left: 8px !important;
    padding-right: 8px;

    &.Mui-selected {
      color: black !important;
      background-color: #fff;
      font-family: Poppins-SemiBold !important;
      border-radius: 6px;
    }
  }

  .MuiTabs-indicator {
    // background-color: $theme-coclor;
    display: none;
  }
}
.custom-candidate-tab {
  .MuiTabs-root {
    min-height: 38px !important;
    // margin-left: 18px !important;
  }

  .MuiTabPanel-root {
    padding: 24px 0px;
  }

  .MuiTabScrollButton-root {
    width: 25px !important;
  }

  // .MuiTabs-flexContainer {
  //  }
  .MuiTab-root {
    color: #929292;
    display: block;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 38px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    &.Mui-selected {
      color: #ee4b22 !important;
      background-color: #fff;
      font-family: Poppins-SemiBold !important;
      border-radius: 6px;
    }
  }

  .MuiTabs-indicator {
    // background-color: $theme-coclor;
    display: none;
  }
}
.custom-toolbar {
  justify-content: space-between;

  .backlabel {
    color: $label-color !important;
    font-family: Poppins-SemiBold !important;
    font-size: 15px !important;
    letter-spacing: 0;
    line-height: 14px;
  }

  .backicon {
    fill: $label-color;
    font-size: 16px !important;
  }

  .vborder {
    height: 40px;
    width: 2px;
    background-color: #d8d8d8;
    margin: 0 20.5px;
  }
}

.pl-20 {
  padding-left: 20px;
}

.pointer {
  cursor: pointer;
}

.headerlogo-text {
  font-size: 38px !important;
  color: $white-color;
  text-align: center;
  font-weight: 500 !important;
  justify-content: center;
  font-family: Poppins-Bold !important;
  padding-top: 5px;

  img {
    width: 160px;
  }
}

.hr {
  font-size: 38px !important;
  color: $theme-coclor;
  font-weight: 500 !important;
  font-family: Poppins-Bold !important;

  img {
    width: 160px;
  }

  &::first-letter {
    letter-spacing: -10px;
  }
}

.collapse-btn {
  // height: 40px;
  // width: 40px;
  // background-color: $theme-coclor !important;
  // border-radius: 50% !important;
  z-index: 100;
  // &:hover{
  //   background-color: $theme-coclor !important;
  // }
}

.smallside,
.normalside {
  .MuiList-root {
    height: 100%;
    padding-top: 32px;
  }

  .MuiDrawer-paper {
    // position: unset !important;
    border-right: unset !important;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &:hover {
      &::-webkit-scrollbar {
        width: 5px;
        background-color: $theme-coclor;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        scrollbar-width: thin;
        z-index: 100;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
        /* Set the color of the thumb */
      }

      &::-webkit-scrollbar-track {
        background: red;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track-piece {
        background: #e8e8e8;
        border-radius: 100px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: $theme-coclor;
        border-radius: 100px;
        cursor: pointer;
      }
    }
  }
}

.draw-header {
  min-height: 72px !important;
  justify-content: space-between !important;
  background-color: #fff;
  border-right: 1px solid $divider-color;
  position: sticky;
  top: 0;
  z-index: 100;

  button {
    img {
      width: auto !important;
      height: auto !important;
    }
  }
}

.smallborder {
  background-color: $divider-color;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.dark-light {
  border-radius: 6px;
  border: 1px solid #f2f4f7;
  background: #f4f4f4;
  height: 52px;

  .active {
    border-radius: 6px;
    border: 1px solid #f9fafb;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.01);
  }

  button {
    .p {
      color: #344051 !important;
      text-transform: capitalize !important;
      font-family: Poppins-Medium !important;
      font-size: 16px !important;
      font-style: normal;
      line-height: 24px;
    }
  }

  img {
    margin-right: 8px;
  }
}

.smallside {
  width: 60px !important;

  .profile-item {
    .MuiListItemText-root {
      display: none;
    }
  }

  .MuiDrawer-paper {
    width: 60px !important;
  }

  .profile-item {
    padding-left: 10px !important;
  }

  .sidebarLink {
    .MuiListItemIcon-root {
      margin-right: unset !important;
    }

    .MuiButtonBase-root {
      padding-left: 20px !important;
    }
  }
}

.set-diveder {
  height: 1px;
  background-color: rgba(177, 180, 190, 0.22);
  margin: 19px 0px !important;
  width: 100%;
}

.w-100 {
  width: 100%;
}

.extra {
  height: 80px;
  background-color: $label-color;
  width: 60px;
  text-align: center;
  padding: 12px 0px;
}

.adminextra {
  height: 72px;
  background-color: $white-color;
  width: 238px;
  text-align: center;
  padding: 12px 0px;
  border-right: 1px solid $divider-color;
  border-bottom: 1px solid $divider-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px !important;
  padding-right: 20px;

  button {
    padding: unset !important;
    margin-left: 4px;

    img {
      width: auto !important;
      height: auto !important;
      // transform: rotate(180deg);
    }
  }
}

.join-text {
  color: #637083 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;

  span {
    color: #344051 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.status-text {
  color: #637083 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: Poppins-SemiBold !important;
}

.add-link {
  font-family: Poppins-Medium !important;
  color: #0052cc !important;
  font-size: 12px !important;
}

.form-box {
  border-radius: 8px;
  background: #fff;
  min-height: calc(100vh - 230px);
  padding: 24px 40px 8px 40px;

  .form-title {
    color: #344051 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 18px !important;
    margin-bottom: 16px !important;
  }
}

.client-form-photo-area {
  // min-height: 160px;
  padding-bottom: 24px;
  width: 100%;

  .side-client-text {
    font-family: Poppins-SemiBold !important;
    color: #344051;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
  }

  .main-box {
    margin-top: 32px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .client-img-container {
    display: flex;
    gap: 16px;

    .img-placeholder {
      // padding: 18px 23px;
      overflow: hidden;
      width: 75px;
      height: 76px;
      border-radius: 42px;
      border: 1px solid #ee4b22;
      background: rgba(238, 75, 34, 0.08);

      .default-pic {
        // padding: 18px 23px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .no-logo {
        margin: auto;
        color: #ee4b22;
        font-family: Poppins-SemiBold !important;
        font-size: 12px;
        font-style: normal;
        line-height: 40px;
        /* 333.333% */
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .img-info-con {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .img-info-title {
        z-index: 1;
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        header {
          font-family: Poppins-Medium !important;
          color: #191632;
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
          /* 142.857% */
        }

        .sub-info {
          color: #727476;
          font-family: Poppins-Medium !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          /* 133.333% */
        }
      }

      .img-click-btn {
        width: 91px;
        color: #5f2ee5;
        font-family: Poppins-Medium !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
        margin-bottom: 4px;
        cursor: pointer;
        // transform: translateY(-4px);
      }
    }
  }
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast--success {
  border-radius: 6px !important;
  border: 1px solid #58db95 !important;
  background: #e0f9e7 !important;
  padding: 12px !important;
  min-height: 52px !important;
  align-items: center;

  .Toastify__close-button {
    align-self: unset !important;
    margin-left: 16px;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast-body {
    padding: unset !important;
    margin: unset !important;

    div {
      font-size: 16px !important;
      color: #1e293b !important;
    }
  }
}

.step-parent-box {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  // margin-top: 24px !important;

  .stepper-title {
    font-size: 18px;
    font-family: Poppins-SemiBold !important;
    color: #344051 !important;
  }
}

.Toastify__toast--error {
  border-radius: 6px !important;
  border: 1px solid #f58969 !important;
  background: #fdeee4 !important;
  padding: 12px !important;
  min-height: 52px !important;
  align-items: center;

  .Toastify__close-button {
    align-self: unset !important;
    margin-left: 16px;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast-body {
    padding: unset !important;
    margin: unset !important;

    div {
      font-size: 16px !important;
      color: #1e293b !important;
    }
  }
}

.btable {
  .sb-table {
    width: calc(100vw - 367px);
    position: relative;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .custom-table {
      thead {
        .w250 {
          width: 150px;
        }

        .w270 {
          width: 200px;
        }

        .w200 {
          width: 150px;
        }

        .w150 {
          width: 120px;
        }
      }
    }
  }

  .sb-table2 {
    width: calc(100vw - 367px);
    // width: 100%;
    position: relative;

    &::-webkit-scrollbar {
      // width: 0px;
      border-radius: 5px;
    }

    .custom-table {
      thead {
        .w250 {
          width: 150px;
        }

        .w270 {
          width: 200px;
        }

        .w200 {
          width: 150px;
        }

        .w150 {
          width: 120px;
        }
      }
    }
  }
}

.stable {
  .sb-table,
  .sb-table2 {
    width: calc(100vw - 189px);
    // width: 100%;
    position: relative;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .custom-table {
      thead {
        .w250 {
          width: 150px;
        }

        .w270 {
          width: 200px;
        }

        .w200 {
          width: 150px;
        }

        .w150 {
          width: 120px;
        }
        .w100 {
          width: 100px;
        }
      }
    }
  }
}

.next-btn {
  right: 0px;
  // right: 15px;
}

.next-btn2 {
  right: -15px !important;
}
.MuiAccordionSummary-content {
  font-size: 14px;
}
.pre-btn {
  left: 0px;
  left: -10px;
  left: -1px;
}

.pre-btn,
.next-btn,
.next-btn2 {
  min-width: 18px !important;
  padding: unset !important;
  height: 18px !important;
  z-index: 9;
  position: absolute !important;
  top: 14px;
}

.custom-switch {
  height: 24px !important;
  width: 44px !important;
  padding: unset !important;
  border-radius: 30px !important;
  margin: 0 14px 0 8px;

  .MuiSwitch-thumb {
    height: 20px !important;
    width: 20px !important;
    border: 0.5px solid rgba(0, 0, 0, 0.04) !important;
    background-color: #ffffff !important;
    margin-top: 1px;
    box-shadow:
      0 3px 8px 0 rgba(0, 0, 0, 0.15),
      0 3px 1px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .MuiSwitch-track {
    background-color: #cbd5e1;
  }

  .MuiButtonBase-root.Mui-checked + .MuiSwitch-track {
    background-color: $theme-coclor !important;
    opacity: 1 !important;
  }

  .MuiSwitch-switchBase {
    padding: 1px !important;
  }
}

.mt-35 {
  margin-top: 35px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mt-150 {
  margin-top: 150px !important;
}
.mt-147 {
  margin-top: 147px !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-8 {
  margin-top: 8px !important;
}
.rti--input {
  width: 100% !important;
}
@media (max-width: 768px) {
  .custom-toolbar {
    .page-title {
      font-size: 16px !important;
      padding-left: 12px !important;
    }
  }

  .banner-text {
    padding: 24px !important;
    font-size: 25px !important;
  }

  .login-box {
    margin: 18px 0 !important;
  }

  .text-left,
  .text-right {
    text-align: center !important;
  }

  .logo-text {
    margin-bottom: unset !important;
  }

  .pl-15 {
    padding-left: unset !important;
  }

  .mb-36 {
    margin-bottom: 10px !important;
  }

  .mt-5r {
    margin-top: 10px !important;
  }

  .login-form {
    width: 100% !important;
    padding: 0px !important;
  }

  .login-banner {
    padding: 10px !important;
  }

  .login-box {
    padding: 0px 20px;
  }

  .mpad-20 {
    padding: 20px !important;
  }

  .px-40 {
    padding: 0px !important;
  }
}

@media (min-width: 768px) {
  .for-log-box {
    width: 368px;
  }
}
.f-family {
  font-size: 14px !important;
  font-family: poppins-regular !important;
}

.react-tel-input .form-control {
  font-size: 14px !important;
  &:focus {
    // border-color: #1976d2;
    // box-shadow: 0 0 0 1px #1976d2;
    // border:none !important;
    // box-shadow: none;
    border: 1px solid #d0d5dd !important;
    background: #fff !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  }

  &:hover {
    border: 1px solid #d0d5dd !important;
  }
}

.phone-input {
  padding: 6px 11px 6px 60px !important;
}

.phone-input-error {
  border: 1px solid $red-color !important;
  border-radius: 6px !important;
  box-shadow: none !important;

  input {
    border: none !important;
  }

  &:hover {
    border: 1px solid $red-color !important;
  }
}

.flag-dropdown {
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--Neutral-Gray-300, #ced2da);
  background: var(--White, #fff);
}

.dialogmoda {
  .cusmoda {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);

    width: 380px;
    height: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
  }

  header {
    font-family: Poppins-SemiBold !important;
    letter-spacing: 0;
    color: #2e162e !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-wrap: nowrap;
  }

  .s-add-btn {
    height: 36px;
    min-width: 96px !important;
    border-radius: 6px !important;
    border: 1px solid $theme-coclor !important;
    background: $theme-coclor !important;
    color: $white-color !important;
    font-family: Poppins-Medium !important;
    font-size: 18px !important;
    line-height: 24px !important;
    padding: 14px 16px;
    text-transform: capitalize !important;
  }
}

.noptioncustomselect {
  background-color: $white-color !important;
  border: none !important;
  width: 100% !important;
  height: 100%;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    background-color: $white-color !important;
    border: none !important;
  }
}

.customoptionbg {
  // overflow-x: hidden;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $white-color !important;
  z-index: 100;

  // overflow-X: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $white-color !important;
    z-index: 100;
    transform: translateX(-20px) translateY(-12px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $white-color !important;
    z-index: 100;
    transform: translateX(12px) translateY(12px);
  }
}

.customoptionbg2,
.customoptionbg3 {
  // overflow-X: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $white-color !important;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $white-color !important;
    z-index: 100;
    transform: translateX(12px) translateY(-20px);
    margin: 0px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $white-color !important;
    transform: translateX(-20px) translateY(10px);
    margin: 0px;
  }
}

.customoptionbg3 {
  &::after {
    transform: translateX(12px) translateY(5px);
  }
}

.Custom-error {
  color: $red-color !important;
  margin: 4px 0px 0px 0px !important;
  font-size: 12px !important;
  font-family: Poppins-Medium !important;
}
.Custom-error-new {
  color: $red-color !important;
  margin: 4px 0px 0px 17px !important;
  font-size: 12px !important;
  font-family: Poppins-Medium !important;
}
.ckeditor-config {
  div.ck-editor {
    // border: 0.5px solid $red-color;
    outline: 0.5px solid $red-color;
    box-shadow: 0px 0px 2.5px $red-color;
  }
}

.display-none {
  display: none;
}

.no-client {
  font-family: Poppins-Medium !important;
  color: #344051;
  font-size: 14px;
  font-style: normal;
  line-height: 14px;
  /* 100% */
}

.client-sucess-con {
  .title {
    font-family: Poppins-SemiBold !important;
    color: #344051;
    font-size: 20px;
    font-style: normal;
    line-height: 20px;
    /* 100% */
  }

  .title2 {
    color: #344051;
    font-size: 15px;
    font-style: normal;
    line-height: 20px;
    /* 100% */
  }

  .approval {
    text-align: center;
    font-family: Poppins-Medium !important;
    color: #ff910f;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
  }
}

.logout-modal {
  .client-success-modal-btn {
    display: flex;
    width: 96px;
    height: 36px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: #ee4b22;

    color: #fff;

    /* Desktop/Body/Small/Small -> Medium */
    font-family: Poppins-Medium !important;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    /* 133.333% */

    &:hover {
      background-color: #ee4b22;

      color: #fff;
    }
  }
}
.leaveCardHeader {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  text-align: justify;
  height: 40px;
  background-color: #fff7e0;
  padding: 12px;
  border-radius: 5px, 5px, 0px, 0px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
  color: #344051;
}
.leavepolicyCard{
  width: 256px;
  height: 270px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
  padding: 16px;

  .client-card {
    width: 100%;

    header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      img {
        width: 40px;
        height: 40px;
        padding: 1px;
        border-radius: 50%;
        object-fit: cover;
      }

      .non-profil {
        transform: translateX(8px);
        display: flex;
        justify-content: space-between;
        gap: 17px;

        .dt-con {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .heading {
            color: #344051;
            font-family: Poppins-SemiBold !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .time {
            color: #637083;
            font-family: Poppins-Regular !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
          }
        }
      }
    }

    main {
      .support {
        color: #344051;
        font-family: Poppins-SemiBold !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        // margin-bottom: 6px;
      }

      .sub-support {
        color: #637083;
        font-family: Poppins-Regular !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 114.286% */
        text-transform: capitalize;
      }

      margin-bottom: 20px;
    }

    footer {
      .createdby {
        color: #344051;
        font-family: Poppins-SemiBold !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .director {
        color: #637083;
        font-family: Poppins-Regular !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      margin-bottom: 6px;
      // margin-bottom: 10px;
    }

    .main-view-btn {
      background-color: #fdede9;
      color: #ff910f;
      text-align: center;
      font-family: Poppins-Medium !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 166.667% */
      outline: none;
      border: none;
      padding: 2px 6px;
      // -
      border-radius: 4px;
      background: #fdede9;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

      margin-bottom: 28px;
      // margin-bottom: 32px;
      // margin-bottom: 19px;
    }
  }

  .footer-btn {
    outline: none;
    border: none;
    display: flex;
    // width: 100%;
    height: 36px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #ee4b22;
    // -font
    color: #fff;
    text-align: center;
    font-family: Poppins-Medium !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
  }
}
.client-card-approve {

  display:grid;

  max-width: 100%;

  min-height: 300px;

  max-height: 100%;

  border-radius: 6px;

  background: #fff;

  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);

  padding: 16px;



  .client-card {
 width: 100%;


 header {
 width: 100%;
 display: flex;
 justify-content: space-between;
 margin-bottom: 12px;


 img {
 width: 40px;
 height: 40px;
 padding: 1px;
 border-radius: 50%;
 object-fit: cover;
 }


 .non-profil {
 transform: translateX(8px);
 display: flex;
 justify-content: space-between;
 gap: 17px;


 .dt-con {
 display: flex;
 flex-direction: column;
 align-items: flex-end;


 .heading {
 color: #344051;
 font-family: Poppins-SemiBold !important;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 }


 .time {
 color: #637083;
 font-family: Poppins-Regular !important;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: 16px;
 /* 133.333% */
 }
 }
 }
 }


 main {
 .support {
 color: #344051;
 font-family: Poppins-SemiBold !important;
 font-size: 18px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 text-transform: capitalize;
 // margin-bottom: 6px;
 }


 .sub-support {
 color: #637083;
 font-family: Poppins-Regular !important;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 16px;
 /* 114.286% */
 text-transform: capitalize;
 }


 margin-bottom: 20px;
 }


 footer {
 .createdby {
 color: #344051;
 font-family: Poppins-SemiBold !important;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 }


 .director {
 color: #637083;
 font-family: Poppins-Regular !important;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 }


 margin-bottom: 6px;
 // margin-bottom: 10px;
 }


 .main-view-btn {
 background-color: #fdede9;
 color: #ff910f;
 text-align: center;
 font-family: Poppins-Medium !important;
 font-size: 12px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
 /* 166.667% */
 outline: none;
 border: none;
 padding: 2px 6px;
 // -
 border-radius: 4px;
 background: #fdede9;
 box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);


 margin-bottom: 28px;
 // margin-bottom: 32px;
 // margin-bottom: 19px;
 }

  }



  .footer-btn {
 outline: none;
 border: none;
 display: flex;
 width: 100%;
 height: 36px;
 padding: 8px 16px;
 justify-content: center;
 align-items: center;
 gap: 8px;
 flex-shrink: 0;
 border-radius: 4px;
 background: #ee4b22;
 // -font
 color: #fff;
 text-align: center;
 font-family: Poppins-Medium !important;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;


 cursor: pointer;

  }

}

.client-aprv-grid-container {
  // width: 100%;
  display: flex;
  flex-flow: row wrap !important;
  gap: 32px 16px;
  // justify-content: space-around;
}

.go-to-clnt-list-btn {
  height: 36px;
  min-width: 96px !important;
  border-radius: 6px !important;
  border: 1px solid $theme-coclor !important;
  background: transparent !important;
  color: #ee4b22 !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 12px;
  text-transform: capitalize !important;
}

.client-apruv-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 818px;
  max-height: 760px;
  height: 95%;
  // max-height: 90vh;
  padding: 29px 30px 32px 32px;
  position: absolute;
  top: 50%;
  // top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  overflow: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2.5px;
    /* Adjust width */
    background-color: #f2f2f2;
    /* Light background */
    border-radius: 8px;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    position: relative;
    margin-bottom: 28px;

    .closepng {
      position: absolute;
      top: -5px;
      right: 0px;
      transform: scale(1.05);
      transform-origin: center;
      cursor: pointer;
    }

    .modal-header {
      display: flex;
      gap: 24px;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      .cnt-info {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;

        .support {
          color: #344051 !important;
          font-family: Poppins-SemiBold !important;
          font-size: 18px;
          font-style: normal;
          line-height: normal;
          text-transform: capitalize;
        }

        .location {
          color: #637083 !important;
          font-family: Poppins-Regular !important;
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
          /* 114.286% */
          text-transform: capitalize;
        }
      }

      .main-view-btn {
        background-color: #fdede9;
        color: #ff910f;
        text-align: center;
        font-family: Poppins-Medium !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 166.667% */
        outline: none;
        border: none;
        padding: 2px 6px;
        // -
        border-radius: 4px;
        background: #fdede9;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

        // margin-bottom: 32px;
        // margin-bottom: 19px;
      }
    }

    .buttons {
      display: flex;
      flex-flow: row nowrap;
      gap: 16px;
      align-items: center;

      .client-aprv-btn {
        transform: translateY(8px);
        height: 36px;
        min-width: 96px !important;
        border-radius: 6px !important;
        border: 1px solid $theme-coclor !important;
        background: transparent !important;
        color: #ee4b22 !important;
        font-family: Poppins-Medium !important;
        font-size: 12px !important;
        line-height: 16px !important;
        padding: 10px 12px;
        text-transform: capitalize !important;
        cursor: pointer;
      }

      .aprvd-btn-2 {
        background-color: #ee4b22 !important;
        color: #fff !important;
      }
    }
  }

  .corporate-heading {
    color: #344051 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    /* 150% */
    text-align: left;
    margin-top: 30px;
    margin-bottom: 24px;
  }

  .corporate-reason-heading {
    margin-bottom: 4px !important;
  }

  .main-reason-modal {
    color: #637083 !important;
    font-family: Poppins-Regular !important;
    font-size: 14px !important;
    font-style: normal !important;
    line-height: 24px !important;
    /* 133.333% */
    text-transform: capitalize !important;
  }

  .first-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(207px, 1fr));
    grid-gap: 24px 72px;
    justify-content: space-between;
    grid-auto-flow: row;

    .section-cards {
      header {
        color: #191632;
        font-family: Poppins-SemiBold !important;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        /* 142.857% */
        margin-bottom: 4px;
      }

      main {
        color: #637083;
        font-family: Poppins-Regular !important;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        /* 142.857% */
        text-transform: capitalize;
        word-break: break-all;
        hyphens: auto;
      }
    }

    padding-bottom: 30px;
    border-bottom: 1px solid #ededed;
  }

  .spoc-detl {
    margin-bottom: 6px;
  }

  .spoc-section {
    border-bottom: none !important;
  }

  .spoc-location {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    margin-bottom: 24px;
    cursor: pointer;

    .spoc-loc-text {
      color: #5135ff !important;
      font-family: Poppins-Regular !important;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      /* 133.333% */
      transform: translateY(1.425px);
    }
  }
}

.spoc-list-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 500px;
  min-height: 250px;
  max-height: 90vh;
  padding: 30px;
  position: absolute;
  top: 50%;
  // top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
    /* Hide scrollbar */
  }

  scrollbar-width: none;
  /* Hide scrollbar */

  &:focus-visible {
    border: none;
    outline: none;
  }

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .closepng {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  header {
    color: #344051;
    font-family: Poppins-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  .divider {
    margin-top: 13px;
    height: 1px;
    background-color: #ededed;
  }

  .custom-field,
  .custom-input {
    width: 100%;
    height: 100%;

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    .MuiInputBase-input {
      padding: 12px;
      min-height: 40px !important;
      border-radius: 6px !important;
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &.Mui-error {
        border: 1px solid $red-color;
      }

      &::before,
      &::after {
        border-bottom: unset;
      }
    }
  }

  .button-con {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 17px 16px;
    padding: 6px;
    padding-top: 22px;

    button {
      cursor: pointer;
      display: flex;
      width: 132px;
      height: 36px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      background-color: transparent;
      border-radius: 6px;
      border: 1px solid #ee4b22;
      // -txt
      color: #ee4b22 !important;
      font-family: Poppins-Medium !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: capitalize;
    }

    button.client-modal-comment-btn {
      background-color: #ee4b22 !important;
      color: #fff !important;
    }

    &.center {
      justify-content: center;
      align-items: center;
    }

    &.end {
      justify-content: flex-end;
    }
  }
}

.spoc-client-info-nav {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .left-ico {
    cursor: pointer;
  }

  .right-ico {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    cursor: pointer;
  }
}

.displayNone {
  display: none;
}
.spoc-client-info-main {
  width: 423px;
  height: 164px;
  margin-top: 18px;

  header {
    color: #344051 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    /* 140% */
    text-transform: capitalize;
  }

  .description,
  .address-spocs {
    // margin-top: 10px;
    color: #637083 !important;
    font-family: Poppins-Regular !important;
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
  }
}

.spoc-client-info-phnumber-con {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  margin-top: 12px;

  .landline {
    color: #637083 !important;
    font-family: Poppins-Regular !important;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  .mobile {
    color: #637083 !important;
    font-family: Poppins-Regular !important;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  .headng {
    color: #637083 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    /* 142.857% */
  }
}

.spoc-client-info-divider {
  height: 1px;
  background-color: #ededed !important;
  margin-top: 20px;
}

.filterfunnel-con {
  cursor: pointer;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.filterfunnel {
  color: #344051 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  /* 142.857% */
}

.clientfilter-header {
  width: 100%;
  margin-top: 56.84px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .heading {
    color: #344051 !important;
    font-family: Poppins-SemiBold !important;
    font-size: 20px;
    font-style: normal;
    line-height: 20px;
    /* 100% */
  }

  .clientfilter-close-ico {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .clearall {
    color: #5135ff !important;
    text-align: center;
    font-family: Poppins-Medium !important;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
  }
}

.clientfilter-divider {
  width: 100%;
  height: 1px;
  background-color: #eaecf0 !important;
  margin-top: 15.73px;
  margin-bottom: 24.78px;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 120%;
    height: 1px;
    background-color: #eaecf0 !important;
    position: absolute;

    top: 0;
  }

  &::before {
    left: -15%;
  }

  &::after {
    right: -15%;
  }
}

.clientfilter-form {
  .custom-field {
    margin-top: 8px;

    & > p {
      margin-bottom: 6px;
    }
  }
}

.clientfilter-form-btn {
  border: none;
  outline: none;
  margin-top: 19.5px;
  margin-bottom: 93px;
  display: flex;
  width: 247px;
  height: 36px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #ee4b22 !important;
  // txt
  color: #fff !important;
  text-align: center;
  font-family: Poppins-Medium !important;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
}
.calendar {
  // display: flex;
  min-height: 100%;
  font-family: Poppins-Medium !important;
  font-size: 16px;
  .f .fc-header-toolbar {
    background-color: #fff;
    color: #344051;
  }
  .fc .fc-button-primary {
    background-color: transparent;
    border: none;
    color: #344051;
  }
  thead {
    background-color: #fdede9;
    height: 50px;
  }
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0px;
  }
  .fc-toolbar {
    .fc-toolbar-chunk {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
      }
    }
  }
  .fc-button {
    text-transform: capitalize !important;
  }
  .fc .fc-button-primary:focus {
    background-color: transparent !important;
    color: #344051 !important;
    box-shadow: none !important;
  }
  .fc .fc-daygrid-day-frame {
    padding: 5px;
  }
  .fc-h-event .fc-event-title-container {
    text-transform: capitalize;
  }
  .fc .fc-button-group > .fc-button.fc-button-active {
    border-radius: 0px;
    background-color: transparent;
    color: #344051;
    border-bottom: 1px solid #344051 !important;
    outline: none;
    border: none;
    box-shadow: none !important;
  }
  .fc-prev-button,
  .fc-today-button,
  .fc-next-button {
    background-color: transparent !important;
    color: #344051;
    border: none;
  }
  .fc .fc-button-primary:disabled {
    background-color: transparent !important;
    color: #344051;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: #fff4f1 !important;
  }
}

.calendar-sidebar {
  width: auto;
  line-height: 1.5;
  background: #fff;
  border-right: 1px solid #d3e2e8;
}
.clientfilter-time-from-to {
  width: 100% !important;
  height: 50px;
  // padding: 8px 9px 8px 14px !important;
  border-radius: 6px;
  border: 1px solid #d0d5dd !important;
  border-color: transparent !important;
  outline: none !important;
  background: transparent !important;
  margin-top: 6px;
  input[type='date'] {
    position: relative;
    padding: 0px 0px 0px 0px !important;
  }

  input[type='datetime-local'] {
    position: relative;
    padding: 0px 0px 0p x 0px !important;
  }

  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d0d5dd !important;
      }
    }

    &.Mui-focused {
      border: 1px solid #d0d5dd !important;

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d0d5dd !important;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    &:focus-visible {
      outline: unset !important;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    cursor: pointer;
    z-index: 1;
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    right: 2px;
    top: 0;
    bottom: 0;
  }

  input[type='date']:after {
    color: transparent;
    background: none;
    display: block;
    content: '';
    /* This is the calendar icon in FontAwesome */
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0px;
    right: 4px;
    color: #999;
    background-image: url(../assets/png/dates.svg);
    background-repeat: no-repeat;
  }

  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    cursor: pointer;
    z-index: 1;
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    right: 2px;
    top: 0;
    bottom: 0;
  }

  input[type='datetime-local']:after {
    color: transparent;
    background: none;
    display: block;
    content: '';
    /* This is the calendar icon in FontAwesome */
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0px;
    right: 2px;
    color: #999;
    background-image: url(../assets/png/dates.svg);
    background-repeat: no-repeat;
  }

  &:hover {
    border-color: transparent !important;
    outline: none !important;
    background: transparent !important;
  }

  &:focus-visible {
    outline: none !important;
    border: none !important;
  }

  & > div {
    width: 100% !important;
    height: 40px;
    padding: 8px 9px 8px 14px !important;
    border-radius: 6px;
    border-color: transparent !important;
    outline: none !important;
    background: transparent !important;

    &:hover {
      border-color: transparent !important;
      outline: none !important;
      background: transparent !important;
    }

    &:focus-visible {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  input {
    border-color: transparent !important;
    /* Shadow/xs */
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #344051;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    /* 171.429% */
    &:focus-visible {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:hover {
      border-color: transparent !important;
      outline: none !important;
      background: transparent !important;

      fieldset {
        border-color: transparent !important;
        outline: none !important;
        background: transparent !important;

        &:focus-visible {
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}

.clientfilter-time-from-to-error {
  & > div {
    border: 1px solid #f00 !important;
  }
}
.report-time-from-to {
  width: 100% !important;
  min-height: 40px;
  // padding: 8px 9px 8px 14px !important;
  border-radius: 6px;
  border: 1px solid #ee4b22 !important;
  border-color: #ee4b22 !important;
  outline: #ee4b22 !important;
  background: transparent !important;
  margin-top: 6px;
  input[type='date'] {
    position: relative;
    padding: 0px 0px 0px 0px !important;
  }

  input[type='datetime-local'] {
    position: relative;
    padding: 0px 0px 0p x 0px !important;
  }

  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ee4b22 !important;
      }
    }

    &.Mui-focused {
      border: 1px solid #ee4b22 !important;

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ee4b22 !important;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    &:focus-visible {
      outline: unset !important;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    cursor: pointer;
    z-index: 1;
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    right: 2px;
    top: 0;
    bottom: 0;
  }

  input[type='date']:after {
    color: transparent;
    background: none;
    display: block;
    content: '';
    /* This is the calendar icon in FontAwesome */
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0px;
    right: 4px;
    color: #999;
    background-image: url(../assets/png/dates.svg);
    background-repeat: no-repeat;
  }

  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    cursor: pointer;
    z-index: 1;
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    right: 2px;
    top: 0;
    bottom: 0;
  }

  input[type='datetime-local']:after {
    color: transparent;
    background: none;
    display: block;
    content: '';
    /* This is the calendar icon in FontAwesome */
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0px;
    right: 2px;
    color: #999;
    background-image: url(../assets/png/dates.svg);
    background-repeat: no-repeat;
  }

  &:hover {
    border-color: transparent !important;
    outline: none !important;
    background: transparent !important;
  }

  &:focus-visible {
    outline: none !important;
    border: none !important;
  }

  & > div {
    width: 100% !important;
    height: 40px;
    padding: 8px 9px 8px 14px !important;
    border-radius: 6px;
    border-color: transparent !important;
    outline: none !important;
    background: transparent !important;

    &:hover {
      border-color: transparent !important;
      outline: none !important;
      background: transparent !important;
    }

    &:focus-visible {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  input {
    border-color: transparent !important;
    /* Shadow/xs */
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #344051;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    /* 171.429% */
    &:focus-visible {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:hover {
      border-color: transparent !important;
      outline: none !important;
      background: transparent !important;

      fieldset {
        border-color: transparent !important;
        outline: none !important;
        background: transparent !important;

        &:focus-visible {
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
.email-tab-divider {
  width: 100%;
  height: 1px;
  background-color: #eaecf0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: -10px;
    // width: 20px;
    height: 2px;
    background-color: $white-color;
  }
}

.MuiButton-root.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.client-comment-box {
  & > div {
    border: none !important;
    box-shadow: none !important;
  }
}

.client-comment-box-error {
  & > div {
    border: 1px solid $red-color !important;
    box-shadow: 0.5px 0.5px -0.5px 0.5px $red-color !important;
  }
}

.ckeditor-container {
  z-index: 2000 !important;
}

.client-inactiv-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 616px;
  max-height: 397px;
  // max-height: 90vh;
  padding: 60px 30px 58px 32px;
  position: absolute;
  top: 50%;
  // top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
  }

  .closepng {
    position: absolute;
    top: 16px;
    right: 16px;
    transform: scale(1.05);
    transform-origin: center;
    cursor: pointer;
  }

  .button-box {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;

    .client-aprv-btn {
      transform: translateY(8px);
      height: 36px;
      min-width: 96px !important;
      border-radius: 6px !important;
      border: 1px solid $theme-coclor !important;
      background: transparent !important;
      color: #ee4b22 !important;
      font-family: Poppins-Medium !important;
      font-size: 12px !important;
      line-height: 16px !important;
      padding: 10px 12px;
      text-transform: capitalize !important;
      cursor: pointer;
    }

    .aprvd-btn-1 {
      background-color: transparent !important;
      border-radius: 6px !important;
      border: 1px solid #637083 !important;

      color: #344051 !important;

      display: flex;
      min-width: 71px !important;
      height: 36px !important;
      padding: 10px 12px !important;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
    }

    .aprvd-btn-2 {
      min-width: 71px !important;
      height: 36px !important;
      padding: 10px 12px !important;
      background-color: #ee4b22 !important;
      color: #fff !important;
    }
  }

  .box-center {
    width: 100%;
    text-align: center;
    // margin-top: 60px;
  }

  .main-title {
    margin-top: 0px;
    color: #344051;
    font-family: Poppins-SemiBold !important;
    font-size: 20px !important;
    font-style: normal !important;
    line-height: 20px !important;
    /* 100% */
  }

  .main-sub-desc {
    margin-top: 24px;
    color: #637083;
    text-align: center;
    font-family: Poppins-Regular !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px;
    /* 150% */
    width: 470px;
    margin-left: auto;
    margin-right: auto;
  }

  .main-sub-highlight {
    margin-top: 30px;
    color: #ee4b22;
    text-align: center;
    font-family: Poppins-Regular !important;
    font-size: 18px !important;
    font-style: normal !important;
    line-height: 24px !important;
    /* 133.333% */
    width: 470px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-box {
    margin-top: 40px;
    justify-content: center;
  }
}

.main-box-corporate-info {
  justify-content: space-between !important;

  .status-text {
    color: #637083 !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px !important;
    /* 150% */
  }
}

.active-inactive-right-modal {
  margin-right: 178px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transform: translateY(-16px);

  header {
    font-family: Poppins-SemiBold !important;
    margin-bottom: 2px;

    color: #344051 !important;
    font-size: 14px !important;
    font-style: normal;
    line-height: 20px;
    /* 142.857% */
  }

  .active-badge {
    // height: 28px;
    // width: 82px;
    border-radius: 12px;
    background: transparent;
    color: #31aa27 !important;
    font-size: 12px !important;
    line-height: 13px;
    text-align: center;
    padding: 0px;
    // width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inactive-badge {
    // height: 28px;
    // width: 90px;
    border-radius: 12px;
    background: transparent;
    color: #f93232 !important;
    font-size: 12px !important;
    line-height: 13px;
    text-align: center;
    padding: 0px;
    padding-left: 0px !important;
    // width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.client-active-badge {
  width: 69.3px;
  height: 26.16px;
  text-align: center;
  padding: 0px;
  font-size: 9px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #16c06e;
  line-height: 18px !important;
  color: white;
  border-radius: 3.6px;
}

.client-inactive-badge {
  width: 69.3px;
  height: 26.16px;
  text-align: center;
  padding: 0px;
  font-size: 9px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f93232;
  line-height: 18px !important;
  color: white;
  border-radius: 3.6px;
}

.ck-content {
  height: 250px !important;
}

.ck-balloon-panel {
  z-index: 1500 !important;
}

.client-inactiv-modal {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 616px;
  max-height: 397px;
  // max-height: 90vh;
  padding: 60px 30px 58px 32px;
  position: absolute;
  top: 50%;
  // top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
  }

  .closepng {
    position: absolute;
    top: 16px;
    right: 16px;
    transform: scale(1.05);
    transform-origin: center;
    cursor: pointer;
  }

  .button-box {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;

    .client-aprv-btn {
      transform: translateY(8px);
      height: 36px;
      min-width: 96px !important;
      border-radius: 6px !important;
      border: 1px solid $theme-coclor !important;
      background: transparent !important;
      color: #ee4b22 !important;
      font-family: Poppins-Medium !important;
      font-size: 12px !important;
      line-height: 16px !important;
      padding: 10px 12px;
      text-transform: capitalize !important;
      cursor: pointer;
    }

    .aprvd-btn-1 {
      background-color: transparent !important;
      border-radius: 6px !important;
      border: 1px solid #637083 !important;

      color: #344051 !important;

      display: flex;
      min-width: 71px !important;
      height: 36px !important;
      padding: 10px 12px !important;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
    }

    .aprvd-btn-2 {
      min-width: 71px !important;
      height: 36px !important;
      padding: 10px 12px !important;
      background-color: #ee4b22 !important;
      color: #fff !important;
    }
  }

  .box-center {
    width: 100%;
    text-align: center;
    // margin-top: 60px;
  }

  .main-title {
    margin-top: 0px;
    color: #344051;
    font-family: Poppins-SemiBold !important;
    font-size: 20px !important;
    font-style: normal !important;
    line-height: 20px !important;
    /* 100% */
  }

  .main-sub-desc {
    margin-top: 24px;
    color: #637083;
    text-align: center;
    font-family: Poppins-Regular !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px;
    /* 150% */
    width: 470px;
    margin-left: auto;
    margin-right: auto;
  }

  .main-sub-highlight {
    margin-top: 30px;
    color: #ee4b22;
    text-align: center;
    font-family: Poppins-Regular !important;
    font-size: 18px !important;
    font-style: normal !important;
    line-height: 24px !important;
    /* 133.333% */
    width: 470px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-box {
    margin-top: 40px;
    justify-content: center;
  }
}

.main-box-corporate-info {
  justify-content: space-between !important;

  .status-text {
    color: #637083 !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px !important;
    /* 150% */
  }
}

.active-inactive-right-modal {
  margin-right: 178px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transform: translateY(-16px);

  header {
    font-family: Poppins-SemiBold !important;
    margin-bottom: 2px;

    color: #344051 !important;
    font-size: 14px !important;
    font-style: normal;
    line-height: 20px;
    /* 142.857% */
  }

  .active-badge {
    // height: 28px;
    // width: 82px;
    border-radius: 12px;
    background: transparent;
    color: #31aa27 !important;
    font-size: 12px !important;
    // line-height: 13px;
    text-align: center;
    padding: 0px;
    // width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inactive-badge {
    // height: 28px;
    // width: 90px;
    border-radius: 12px;
    background: transparent;
    color: #f93232 !important;
    font-size: 12px !important;
    // line-height: 13px;
    text-align: center;
    padding: 0px;
    padding-left: 0px !important;
    // width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.position-container-field {
  height: auto !important;
}

.position-white-box {
  height: auto !important;
  min-height: auto !important;
  // padding: 24px 40px !important;
  padding: 12px 40px !important;
  padding-top: 24px !important;
}

.custom-table-client {
  & tbody td {
    border-bottom: NONE !important;
  }

  & tbody tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  & tbody .inactive-badge + span {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  & tbody .active-badge + span {
    margin-right: 8px !important;
  }
}

.client-status-edit-icon-colum {
  vertical-align: middle !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  padding-right: 0px !important;
  min-width: 100px !important;

  .innercontainer {
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: center !important;
    height: 100% !important;
    // width: 100% !important;
  }
}

.position-white-box-2 {
  margin-top: 24px !important;
}

// .custom-table-client {
//   &  tbody td {
//     line-height: 0px !important;
//   }
//   &   tbody .active-badge {
//     line-height: normal !important;
//     height: auto !important;
//   }
// }

.common-text {
  color: #344051;
  font-family: Poppins-Regular;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  /* 114.286% */
}

.reason-for-editing {
  margin-top: 12px !important;
  margin-bottom: 32px !important;
  text-align: center;
  color: #344051 !important;
  font-family: Poppins-SemiBold !important;
  font-size: 20px !important;
  font-style: normal !important;
  line-height: 20px !important;
  /* 100% */
}

.custom-table tbody .approved {
  color: #60b158 !important;
}

.custom-table tbody .notapproved {
  color: #fbbc05 !important;
}

.editreason-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    border-radius: 6px !important;

    height: 36px !important;
    min-width: 64px !important;
    width: auto !important;
    padding: 10px 12px !important;
  }

  button.editreason-btn-close {
    border: 1px solid #637083 !important;
    background-color: transparent !important;
    color: #344051 !important;
  }

  button.editreason-btn-submit {
    background-color: #ee4b22 !important;
    margin-left: 16px !important;
    color: #fff !important;
  }
}

.position-view-btn {
  cursor: pointer;
  border: none;
  outline: none;
  background: #f257ce;
  box-shadow: 0px 0.900009px 1.80002px rgba(0, 0, 0, 0.05);
  border-radius: 3.60004px !important;
  padding: 1.8px 10.8px 1.8px 10.8px;
  // text
  min-width: 115px !important;
  // max-width: 89px !important;
  width: auto !important;
  height: 21.6px;

  span {
    width: auto !important;
    white-space: nowrap !important;
    padding: 0px 14.5px !important;
    // display: inline-block;
    // width: 41px !important;
    // height:18px !important;
    font-family: Poppins-Medium !important;
    font-style: normal !important;
    // font-size: 9.4px !important;
    font-size: 10px !important;
    line-height: 20px !important;
    /* identical to box height, or 200% */
    text-align: center;

    color: #ffffff;
  }
}

.interview-status-btn {
  cursor: pointer;
  border: none;
  outline: none;

  box-shadow: 0px 0.900009px 1.80002px rgba(0, 0, 0, 0.05);
  border-radius: 3.60004px !important;
  padding: 1.8px 10.8px 1.8px 10.8px;
  // text
  min-width: 69.3px !important;
  width: auto !important;

  &.scheduled {
    background: #16c06e;
  }

  &.re-scheduled {
    background: #ff9920;
  }

  span {
    width: auto !important;
    white-space: nowrap !important;
    padding: 0px 14.5px !important;
    font-family: Poppins-Medium !important;
    font-style: normal !important;
    font-size: 9px !important;
    line-height: 20px !important;
    text-align: center;
    color: #ffffff !important;
  }
}
.client-status-btn {
  cursor: pointer;
  border: none;
  outline: none;

  box-shadow: 0px 0.900009px 1.80002px rgba(0, 0, 0, 0.05);
  border-radius: 3.60004px !important;
  padding: 1.8px 10.8px 1.8px 10.8px;
  // text
  min-width: 69.3px !important;
  width: auto !important;

  &.scheduled {
    background: #16c06e;
  }

  &.re-scheduled {
    background: #ff9920;
  }

  span {
    width: auto !important;
    white-space: nowrap !important;
    padding: 0px 14.5px !important;
    font-family: Poppins-Medium !important;
    font-style: normal !important;
    font-size: 10px !important;
    line-height: 20px !important;
    text-align: center;
    color: #fff !important;
  }
}
.basic-details-position {
  /* Basic Details */
  font-family: 'Poppins-SemiBold !important';
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 27px !important;
  /* identical to box height */

  color: #344051 !important;
  margin-bottom: 16px !important;
  font-weight: 600 !important;
}

.custom-input-position {
  div textarea {
    height: 192px !important;
  }
}

.custom-field p.position-upload-file-hed {
  font-family: 'Poppins-Medium' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  margin-top: 24px;
  margin-bottom: 17px;
}

.custom-field .container-position .img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.custom-field .container-position {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
  padding: 16px 24px 16px 24px !important;
}

.custom-field .container-position-error {
  border: 1px solid #ee4b22 !important;
}

.custom-field .fil-uplod-desc {
  padding: 0px;
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  // gap: 4px;
  .first-heading {
    margin: 0px;
    margin-bottom: 4px;

    .frst-span {
      cursor: pointer;
      font-family: 'Poppins-SemiBold' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      /* identical to box height, or 143% */

      /* Primary/700 */
      color: #6941c6 !important;
    }

    .drago {
      cursor: default;
      font-family: 'Poppins-Regular' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      /* identical to box height, or 143% */

      /* Gray/500 */
      color: #667085 !important;
    }
  }

  .docx {
    margin: 0px;
    cursor: default;
    font-family: 'Poppins-Regular';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    /* identical to box height, or 150% */
    text-align: center !important;

    /* Gray/500 */
    color: #667085 !important;
  }
}

.file-view-box {
  .first-children {
    border: 1px solid #e41d1d !important;
    max-width: 320px !important;
    min-height: 30px !important;
    border-radius: 4px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    .image-container {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      .texted {
        font-family: 'Poppins-Regular' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        color: #0f0f0f !important;

        span {
          color: #0f0f0f !important;
        }
      }
    }

    .image {
      width: 18px !important;
      height: 18px !important;
      margin-right: 8px !important;

      & > svg {
        width: 100%;
        height: 100%;
      }

      transform: translateY(-2px);
      // img {
      //   transform: translateY(4px);
      // }
    }

    .image-success {
      transform: translateY(0px);
    }

    .Crossicon {
      cursor: pointer;
      transform: translateY(2px);
    }

    .Crossicon-deletebucket {
      margin-left: 10px;
      transform: translateY(4px) scale(1.25);
    }
  }

  .first-green-children {
    border: 1px solid #11af22 !important;
  }

  .first-green-children-no-border-color {
    border: 1px solid transparent !important;
  }
}

.private-form .custom-field p.errored-position {
  width: 320px;
  font-family: 'Poppins-Medium' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 18px !important;
  color: #e41d1d !important;
}

.ppticon-svg-text-style {
  font-family: 'Poppins-Medium' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 8px !important;
}

.file-view-box .first-children .image-container .texted-success {
  transform: translateY(2px) !important;
}

.phnumber-posiotion {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    appearance: textfield !important;
  }
}

.position-currency-option {
  font-family: 'Poppins-Regular' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* identical to box height, or 200% */

  color: #637083 !important;

  option {
    font-family: 'Poppins-Regular' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    /* identical to box height, or 200% */

    color: #637083 !important;
  }
}

.awaitingApproval {
  span {
    color: #ffffff !important;
  }

  // background: hsl(45,97,90)!important;
  background: #f5aa1a !important;
}

.assigned {
  span {
    color: #fff !important;
  }

  background: #70a415 !important;
}

// .rejected{color:'';}
.inProcess {
  background: #5856d6 !important;

  span {
    color: #fff !important;
  }
}

.onHold {
  background: #cbf340 !important;

  span {
    color: #fff !important;
  }
}

.billed {
  background: #52e0e0 !important;

  span {
    color: #fff !important;
  }
}
.rejected {
  background: red !important;

  span {
    color: #fff !important;
  }
}
.awaitingApprovalCRM {
  background: #637083 !important;

  span {
    color: #fff !important;
  }
}
.terminated {
  background: #f3733f !important;

  span {
    color: #fff !important;
  }
}

.taginput {
  &.disabled {
    .rti--container {
      background-color: #f0f0f0;
      > input {
        background-color: transparent;
      }
    }
  }
  .rti--container {
    padding: 7px inherit !important;
    --rti-bg: #fff !important;
    --rti-border: #ccc !important;
    --rti-main: #d0d5dd !important;
    --rti-radius: 0.375rem;
    --rti-s: 0.5rem !important;
    /* spacing */
    --rti-tag: #fdede9 !important;
    --rti-tag-remove: #ffffff !important;
    padding: 5px !important;

    .rti--tag {
      border-radius: 4px !important;
      position: relative !important;
      // &::before {
      //   content:'';
      //   position: absolute  !important;
      //   top: 0  !important;
      //   right: 0  !important;
      //   bottom: 0 !important;
      //   border-radius: 100% !important;
      //   background-color: #89888E !important;
      //   height: 14px !important;
      //   width: 14px !important;
      //   transform: translate(-57.85%, 68%);
      // }
    }

    .rti--tag button {
      color: #ffffff !important;
      font-family: Poppins-SemiBold !important;
      font-weight: 1000 !important;
      font-size: 8px !important;
      z-index: 1;
      text-transform: capitalize !important;
      background-color: #89888e !important;
      padding: 2.5px 5px !important;
      border-radius: 50%;
      margin-left: 8px;
    }

    .rti--tag > span {
      // min-height: 20px !important;
      padding: 6.5px 2px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      /* Text */

      font-family: Poppins-Medium !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      // line-height: 20px !important;
      /* identical to box height, or 167% */
      text-align: center !important;

      /* Neutral/N-600 */
      color: #4a5569 !important;
    }
  }
}

.errorposition {
  color: #f00 !important;
  margin: 4px 0px 0px 0px !important;
  font-size: 12px !important;
  font-family: Poppins-Medium !important;
}

.tagselecterror {
  .rti--container {
    border: 1px solid #f00 !important;
  }
}

.recuiter-tags {
  // border: 1px solid #ffbdbd;
  background-color: #fdede9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  padding: 6px !important;
  margin-top: 8px;
  gap: 4px;
  border-radius: 3px;

  span {
    //styleName: Caption/Semi Bold;
    font-family: Poppins-SemiBold !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    letter-spacing: 0em !important;
    color: #637083 !important;
  }

  img {
    cursor: pointer;
  }
}

// .ppticon-full-svg {
//   transform: scale(0.7) !important;
// }

.view-position {
  .view-position-white-box {
    header {
      width: 100%;
      height: 84px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      .left-header {
        display: flex;
        gap: 12px;
        align-items: center;

        .main-img {
          width: 60px;
          height: 60px;
          border-radius: 50%;

          img.companylogo {
            // width: 100%;
            // height: 100%;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .company-info {
          .firstname {
            height: 21px !important;
            font-family: 'Poppins-SemiBold !important';
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            line-height: 21px !important;
            /* identical to box height */
            text-transform: capitalize !important;
            color: #344051 !important;
          }

          .secondname {
            margin-top: 6px;
            display: flex;
            align-items: center;

            .name {
              font-family: 'Poppins-Regular !important';
              font-style: normal !important;
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 18px !important;
              /* identical to box height, or 113% */
              text-transform: capitalize !important;
              color: #344051 !important;
            }

            .btns {
              margin-left: 16px;

              .position-view-btn {
                padding: 1.8px 2.8px 1.8px 2.8px;
                min-width: auto !important;
                width: auto !important;
                height: auto;
              }
            }

            .edit {
              margin-left: 7.7px !important;
            }
          }
        }
      }

      .right-header {
        .title {
          font-family: 'Poppins-SemiBold  !important';
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          /* identical to box height, or 143% */

          color: #344051 !important;
        }

        .sub-title {
          margin-top: 6px;
          font-family: 'Poppins-Regular !important';
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          /* identical to box height, or 143% */
          display: flex !important;
          align-items: center !important;

          color: #637083 !important;
        }
      }
    }

    .section-heading {
      font-family: 'Poppins-SemiBold  !important';
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      /* identical to box height, or 150% */

      color: #344051 !important;
      margin-top: 24px;
    }

    .main-content {
      padding-left: 4px;
      padding-right: 6px;
      padding-bottom: 0px;
      width: 100%;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: transparent;
      gap: 40px 16px;
    }
  }
}

.child-items {
  max-width: 255px;
  flex: 1 0 255px;

  .heading {
    font-family: 'Poppins-SemiBold  !important';
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */

    color: #344051 !important;
  }

  .sub-heading {
    margin-top: 4px;
    font-family: 'Poppins-Regular !important';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: #637083 !important;
    
  }
  .email-heading {
    margin-top: 4px;
    font-family: 'Poppins-Regular !important';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */
    display: flex !important;
    align-items: center !important;
    color: #637083 !important;
  }
}
.sub-heading-New{
    font-family: 'Poppins-Regular !important';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: #637083 !important;
    p {
      margin: 0px;
    }
}
.position-apruv-container {
  justify-content: center !important;
  gap: 24px 26.69px !important;
}

.position-apruv-card {
  width: 348.31px;
  height: 270px;
  height: auto;
  border-radius: 5.4px;
  padding: 17px 17.31px 20px 21.62px;
  padding-top: 0px;
  border-radius: 0px 0px 6px 6px;
  overflow-x: hidden;

  .uplinedesign {
    width: 100%;
    height: 10px;
    background-color: #f0eefb;
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;
      right: -20px;
      width: 100%;
      height: 10px;
      background-color: #f0eefb;
    }

    &::before {
      left: -30px;
    }
  }

  .client-card {
    margin-top: 17px;

    main {
      .company {
        color: #344051 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        font-family: 'Poppins-SemiBold  !important';
        font-weight: 600 !important;
        text-transform: capitalize !important;
      }

      .description {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .descon {
          display: flex;
          flex-direction: column;
          gap: 7.18px;

          .jobtitle {
            color: #344051 !important;
            font-family: Poppins-Regular !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            letter-spacing: 0em !important;
            text-align: left !important;
            text-transform: capitalize !important;
          }

          .time {
            color: #637083 !important;

            span:nth-child(1) {
              font-size: 13px !important;
              line-height: 15px !important;
              letter-spacing: 0em !important;
              text-align: left !important;
              font-family: 'Poppins-SemiBold  !important';
              font-weight: 600 !important;
            }

            span:nth-child(2) {
              font-family: Poppins-Regular !important;
              font-size: 13px !important;
              font-weight: 400 !important;
              line-height: 15px !important;
              letter-spacing: 0em !important;
              text-align: left !important;
            }
          }
        }

        .openings {
          align-self: end !important;

          span:nth-child(1) {
            color: #344051 !important;
            font-size: 13px !important;
            line-height: 15px !important;
            letter-spacing: 0em !important;
            text-align: left !important;
            font-family: 'Poppins-SemiBold  !important';
            font-weight: 600 !important;
            text-transform: capitalize !important;
          }

          span:nth-child(2) {
            color: #637083 !important;
            font-family: Poppins-Regular !important;
            font-size: 13px !important;
            font-weight: 400 !important;
            line-height: 15px !important;
            letter-spacing: 0em !important;
            text-align: left !important;
            text-transform: capitalize !important;
          }
        }
      }
    }

    .divider {
      background: #d9d9d9;
      width: 100%;
      height: 1px;
      margin-top: 10px;
    }

    footer {
      .openings {
        margin-top: 9px;

        span:nth-child(1) {
          color: #344051 !important;
          font-size: 13px !important;
          line-height: 15px !important;
          letter-spacing: 0em !important;
          text-align: left !important;
          font-family: 'Poppins-SemiBold  !important';
          font-weight: 600 !important;
          text-transform: capitalize;
        }

        span:nth-child(2) {
          color: #637083 !important;
          font-family: Poppins-Regular !important;
          font-size: 13px !important;
          font-weight: 400 !important;
          line-height: 15px !important;
          letter-spacing: 0em !important;
          text-align: left !important;
          text-transform: capitalize;
        }
      }

      .content-wuth-btn {
        margin-top: 14.09px;
        display: flex;
        justify-content: space-between;

        .footerdesc {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .footer-leftr {
            color: #344051 !important;
            font-size: 13px !important;
            line-height: 15px !important;
            letter-spacing: 0em !important;
            text-align: left !important;
            font-family: 'Poppins-SemiBold  !important';
            font-weight: 600 !important;
          }

          .footer-sub {
            color: #637083 !important;
            font-family: Poppins-Regular !important;
            font-size: 13px !important;
            font-weight: 400 !important;
            line-height: 15px !important;
            letter-spacing: 0em !important;
            text-align: left !important;
          }
        }

        .footer-btn {
          align-self: flex-end !important;
          width: 110px !important;
          width: 120px !important;
          height: 32px !important;
          padding: 7.2px 14.4px 7.2px 14.4px !important;
          padding: 9.5px 11.5px !important;
          border-radius: 5.4px !important;
          gap: 7.2px !important;
          font-family: Poppins-Medium !important;
          font-size: 11px !important;
          font-weight: 500 !important;
          line-height: 13px !important;
          letter-spacing: 0em !important;
          text-align: center !important;
        }
      }
    }
  }
}

.position-only-modal {
  padding: 0px !important;
  position: relative;

  .closepng {
    position: absolute !important;
    top: 24px !important;
    right: 24px !important;
  }
}

.main-content-modal-spoc-2 {
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
}

.child-items2 {
  .heading {
    font-family: 'Poppins-SemiBold  !important';
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */

    color: #344051 !important;
  }

  .sub-heading {
    margin-top: 4px;
    font-family: 'Poppins-Regular !important';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    /* identical to box height, or 143% */
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize;
    color: #637083 !important;
  }
}

.apruv-whitebox {
  position: relative;

  .leftpaginationscg,
  .rightpaginationscg {
    cursor: pointer;
    position: absolute;
  }

  .leftpaginationscg {
    left: -14px;
    top: 50%;
  }

  .rightpaginationscg {
    right: -14px;
    top: 50%;
  }
}

.title-myplan {
  font-family: 'Poppins-SemiBold !important';
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.myplan-modal {
  position: relative;
  .closepng {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .title {
    margin-top: 40px !important;
    font-family: 'Poppins-SemiBold !important';
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
  }
  .custom-field-my-plan {
    .MuiFormControlLabel-root {
      // margin-right: 24px !important;
      margin-right: 9px !important;
    }
    .custom-radio {
      border: 1px solid #e4e7ec !important;
      border-top: transparent !important;
      padding: 20px !important;
    }
  }
  .MuiRadio-root {
    padding: unset;

    &.Mui-checked {
      color: $theme-coclor !important;
    }
  }
}
.title-my-plan {
  margin-top: 40px;
  border: 1px solid #e4e7ec;
  padding: 9px 21px !important;
  .span1 {
    font-family: 'Poppins-Medium !important';
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    color: #344051 !important;

    font-family: 'Poppins-SemiBold !important';
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    // text-align: center !important;
  }
  span {
    color: #f93232 !important;
  }
}

input[type='date'].hidetimepicker::-webkit-calendar-picker-indicator {
  display: none;
}

input[type='datetime-local'].hidetimepicker::-webkit-calendar-picker-indicator {
  display: none;
}

.Mui-disabled1 {
  background-color: hsl(0, 0%, 95%) !important;
  &:hover {
    background-color: hsl(0, 0%, 95%) !important;
  }
}

.Myplantooltipcomp {
  padding: 24px !important;
  color: #344051 !important;
  background-color: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(52, 64, 81, 0.1) !important;
  border-radius: 6px !important;
  font-family: 'Poppins-Medium !important';
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  transform: translateX(20px);
}

.Myplantooltipcomp-arrow {
  // transform: translateX(20px);
  background-color: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(52, 64, 81, 0.03) !important;
}

.Myplantooltipcontent {
  font-family: 'Poppins-Medium !important';
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  /* identical to box height, or 114% */
  color: #344051 !important;
  cursor: pointer !important;
  &:nth-child(1) {
    margin-bottom: 24px !important;
  }
}

.resume-duplication-modal {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(52, 64, 81, 0.25);
  width: 865px;
  height: 711px;
  padding: 24px 22px 54px 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    position: relative;
    margin-bottom: 10px;

    .closepng {
      position: absolute;
      top: -5px;
      right: 0px;
      transform: scale(1.05);
      transform-origin: center;
      cursor: pointer;
    }
  }
}

.resume-ignore-btn {
  height: 36px;
  min-width: 96px !important;
  border-radius: 6px !important;
  border: 1px solid $theme-coclor !important;
  background: transparent !important;
  color: #ee4b22 !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 12px;
  text-transform: none !important;
}

.resume-save-btn {
  border-radius: 4px !important;
  height: 36px;
  min-width: 64px !important;
  background: $theme-coclor !important;
  color: $white-color !important;
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 14px 12px !important;
  text-transform: none !important;
}
.pdf-viewer .rpv-core__annotation {
  border: unset !important;
}
.pdf-viewer .rpv-core__annotation a:hover {
  background-color: unset !important;
  background: unset !important;
  cursor: unset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rpv-core__minimal-button {
  color: #fff !important; /* Set text color to white */
  display: flex;
  align-items: center;
}
.rpv-core__page-layer::after {
  box-shadow: unset !important;
}
.rpv-core__minimal-button:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ) !important; /* Optional: Add a light white hover effect */
}

@-moz-document url-prefix() {
  @media only screen and (min-width: 730px) {
    .next-btn {
      right: 15px !important;
    }
  }
  @media (max-width: 1600px) {
    .next-btn {
      // right: 0px;
      right: 15px;
    }
  }
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
// @media only screen and (min-width: 730px) {
//   .next-btn {
//     right: 15px !important;
//   }
// }

.tag-select {
  &::placeholder {
    color: #bdbdbd !important;
    font-size: 14px !important;
  }
}

.billing-approved-btn {
  width: 40px;
  height: 21.6px;
  border-radius: 3.6px;
  padding: 3px;
  font-size: 9px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billing-invoice-btn {
  width: 40px;
  height: 21.6px;
  border-radius: 3.6px;
  padding: 3px;
  font-size: 9px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leave-status-batch {
  height: 28px;
  width: 90px;
  border-radius: 12px;
  // background: #fff0f0;
  font-size: 12px !important;
  line-height: 13px;
  text-align: center;
  padding: 6px 18px;
  // width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-event {
  color: #fff;
  font-weight: normal;
  padding-left: 4px;
  padding-right: 4px;
  .fc-daygrid-event-dot {
    display: none;
  }
  .fc-event-title {
    font-weight: normal;
  }
}
// Don't edit this colour classesd
.CD50000 {
  background-color: #d50000;
  &:hover {
    background-color: #d50000;
  }
}
.CE67C73 {
  background-color: #e67c73;
  &:hover {
    background-color: #e67c73;
  }
}
.CF4511E {
  background-color: #f4511e;
  &:hover {
    background-color: #f4511e;
  }
}
.CF6BF26 {
  background-color: #f6bf26;
  &:hover {
    background-color: #f6bf26;
  }
}
.C33B679 {
  background-color: #33b679;
  &:hover {
    background-color: #33b679;
  }
}
.C0B8043 {
  background-color: #0b8043;
  &:hover {
    background-color: #0b8043;
  }
}
.C039BE5 {
  background-color: #039be5;
  &:hover {
    background-color: #039be5;
  }
}
.C00E096 {
  background-color: #00e096;
  &:hover {
    background-color: #00e096;
  }
}
.C7986CB {
  background-color: #7986cb;
  &:hover {
    background-color: #7986cb;
  }
}
.C3F51B5 {
  background-color: #3f51b5;
  &:hover {
    background-color: #3f51b5;
  }
}
.C616161 {
  background-color: #616161;
  &:hover {
    background-color: #616161;
  }
}
.C8E24AA {
  background-color: #8e24aa;
  &:hover {
    background-color: #8e24aa;
  }
}

.antd-custom-wrapper {
  position: relative;
  .antd-date-picker-scss {
    .ant-picker-input input {
      opacity: 0 !important;
    }
  }
  .custom-date-antd-placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: calc(94% - 14px) !important;
    padding-right: 16px !important;
    border: 0px transparent !important;
    background: transparent;
    color: #344051;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    pointer-events: none !important;
  }
  .select-gray-antd {
    color: #bdbdbd !important;
  }
}

.ant-picker-dropdown {
  z-index: 1300 !important;
}
.totalContent {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
  color: rgba(52, 64, 81, 1);
}
.ant-picker-outlined {
  &:focus,
  &:focus-within,
  &:hover {
    border-color: #57a8e9;
    border-color: transparent !important;
    border: 1px solid #d0d5dd !important;
    box-shadow: none !important;
  }
}
/* Hide the scrollbar by default */
.MuiMultiSectionDigitalClockSection-root {
  overflow-y: hidden; /* Hide the scrollbar by default */
}

/* Show the scrollbar when hovering */
.MuiMultiSectionDigitalClockSection-root:hover {
  overflow-y: scroll; /* Show the scrollbar on hover */
}

/* Custom scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  width: 1px; /* Very thin scrollbar */
}

.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Scrollbar color */
  border-radius: 2px; /* Rounded corners for scrollbar */
}

.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Darker on hover */
}
/* For Firefox */
.MuiMultiSectionDigitalClockSection-root {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* Color of scrollbar and background */
}

/* Flip card container */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 200px;
  perspective: 1000px; /* 3D space for flip effect */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

/* Flip effect on hover */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Hide back content during flip */
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-front {
  background-color: #f1f1f1;
  color: black;
}

.flip-card-back {
  background-color: #3f51b5; /* Change the color on the back side */
  color: white;
  transform: rotateY(180deg);
}
.fc .fc-scroller::-webkit-scrollbar {
  width: 2px; /* Thin scrollbar */
}

.fc .fc-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Scrollbar color */
  border-radius: 2px; /* Rounded corners for scrollbar */
}

.fc .fc-scroller::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Darker on hover */
}
.calendar .fc .fc-button-primary {
  margin-right: 10px;
}